<template>
  <tarifvergleich
    :leistungenMB100="leistungenMB100"
    :leistungenMP100="leistungenMP100"
  ></tarifvergleich>
</template>

<script>
import { aerzteBest } from "./data/tarife/aerzte_best";
import { aerztePlus } from "./data/tarife/aerzte_plus";
import tarifvergleich from "./templates/Tarifvergleich.vue";
export default {
  components: {
    tarifvergleich
  },
  data() {
    return {
      leistungenMB100: aerzteBest,
      leistungenMP100: aerztePlus
    };
  }
};
</script>