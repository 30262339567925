import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //STATE
  state: {
    drawer: false,
    dialog: false,
    aktuelleJAEG: 64350,
    datenschutz: false,
    allianzBlau: '#004A94',
  },

  //MUTATIONS
  mutations: {
    setDatenschutz: (state, payload) => {
      state.datenschutz = payload
    },
    setDialog: (state, payload) => {
      state.dialog = payload
    },
    setDrawer: (state, payload) => {
      state.drawer = payload
    },
  },

  //ACTIONS
  actions: {},

  //GETTERS
  getters: {
    drawer: (state) => state.drawer,
    dialog: (state) => state.dialog,
    aktuelleJAEG: (state) => state.aktuelleJAEG,
    datenschutz: (state) => state.datenschutz,
    allianzBlau: (state) => state.allianzBlau,
  },
})
