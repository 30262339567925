<template>
  <v-container>
    Rückstellungen für Beitragsrückerstattungen (RfB)
    <p></p>

    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'RfB von 2011 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.allianz.de/" target="_blank">APKV Geschäftsbericht</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Rückstellungen für BRE in Mio',
            },
          },

          //RIGHT
          {
            title: {
              text: 'RfB-Quote',
            },
            opposite: true,
            min: 20,
            max: 50,
          },
          //RIGHT
          {
            title: {
              text: 'RfB-Zuführungsquote',
            },
            opposite: true,
            min: 0,
            max: 20,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Rückstellungen für BRE in Mio',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 1523],
              [2012, 1602],
              [2013, 1686],
              [2014, 1916],
              [2015, 1866],
              [2016, 1933],
              [2017, 1730],
              [2018, 1642],
              [2019, 1556],
              [2020, 1453],
              [2021, 1433],
              [2022, 1645],
              [2023, 1578],
            ],
            // sample data
          },
          {
            type: 'spline',
            name: 'RfB-Quote',
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 44.9],
              [2012, 46.4],
              [2013, 48.3],
              [2014, 56.6],
              [2015, 55.2],
              [2016, 57],
              [2017, 49.8],
              [2018, 45.7],
              [2019, 42.2],
              [2020, 37.6],
              [2021, 35.9],
              [2022, 40.2],
              [2023, 37.0],
            ],
          },
          {
            type: 'spline',
            name: 'RfB-Zuführungsquote',
            yAxis: 2,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 12.3],
              [2012, 15.4],
              [2013, 14.1],
              [2014, 13.5],
              [2015, 11.3],
              [2016, 11.7],
              [2017, 11.9],
              [2018, 10.8],
              [2019, 12.1],
              [2020, 11.5],
              [2021, 13.7],
              [2022, 12.6],
              [2023, 10.3],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
