<template>
  <v-container>
    Einnahmen und Ausgaben, Schadenquote
    <p></p>

    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Einnahmen und Ausgaben von 2011 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.allianz.de/" target="_blank">APKV Geschäftsbericht</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Beitragseinnahmen in Mio',
            },
            min: 0,
            max: 5000,
          },

          //RIGHT
          {
            title: {
              text: 'Leistungsausgaben in Mio',
            },
            min: 0,
            max: 5000,
            opposite: true,
          },
          //RIGHT
          {
            title: {
              text: 'Schadenquote in %',
            },
            opposite: true,
            min: 0,
            max: 100,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Beitragseinnahmen in Mio',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 3190],
              [2012, 3254],
              [2013, 3285],
              [2014, 3236],
              [2015, 3253],
              [2016, 3282],
              [2017, 3354],
              [2018, 3461],
              [2019, 3571],
              [2020, 3731],
              [2021, 3884],
              [2022, 3990],
              [2023, 4149],
            ],
            // sample data
          },
          {
            type: 'column',
            name: 'Leistungsausgaben in Mio',
            yAxis: 1,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 2434],
              [2012, 2453],
              [2013, 2565],
              [2014, 2552],
              [2015, 2693],
              [2016, 2769],
              [2017, 2864],
              [2018, 3022],
              [2019, 3039],
              [2020, 3200],
              [2021, 3182],
              [2022, 3333],
              [2023, 3667],
            ],
          },
          {
            type: 'spline',
            name: 'Schadenquote in %',
            yAxis: 2,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 78.5],
              [2012, 75.9],
              [2013, 76.5],
              [2014, 76.8],
              [2015, 79.2],
              [2016, 80.7],
              [2017, 79.5],
              [2018, 80.0],
              [2019, 77.6],
              [2020, 79.7],
              [2021, 76.0],
              [2022, 77.4],
              [2023, 81.6],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
