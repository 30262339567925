export const fachrichtungen = [
  'Medizinstudent*in',
  'Allgemeinmedizin',
  'Anästhesiologie',
  'Anatomie',
  'Arbeitsmedizin (Betriebsmedizin)',
  'Augenheilkunde',
  'Biochemie',
  'Chirurgie',
  'Frauenheilkunde und Geburtshilfe (Gynäkologie)',
  'Hals-Nasen-Ohrenheilkunde',
  'Haut- und Geschlechtskrankheiten',
  'Humangenetik',
  'Hygiene und Umweltmedizin',
  'Innere Medizin',
  'Kinder- und Jugendmedizin',
  'Kinder- und Jugendpsychiatrie und -psychotherapie',
  'Laboratoriumsmedizin',
  'Mikrobiologie, Virologie und Infektionsepidemiologie',
  'Mund-Kiefer-Gesichtschirurgie',
  'Neurochirurgie',
  'Neurologie',
  'Nuklearmedizin',
  'Öffentliches Gesundheitswesen',
  'Pathologie',
  'Pharmakologie',
  'Phoniatrie und Pädaudiologie',
  'Physikalische und Rehabilitative Medizin',
  'Physiologie',
  'Psychiatrie und Psychotherapie',
  'Psychosomatische Medizin und Psychotherapie',
  'Radiologie',
  'Rechtsmedizin',
  'Strahlentherapie',
  'Transfusionsmedizin',
  'Urologie',
]
