<template>
  <tarif :leistungen="leistungen"></tarif>
</template>

<script>
import { aerzteBest } from "./data/tarife/aerzte_best";
import tarif from "./templates/Tarif.vue";
export default {
  components: {
    tarif
  },
  data() {
    return {
      leistungen: aerzteBest
    };
  }
};
</script>