import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Gesundheitskosten from '../components/Gesundheitskosten.vue'
import Beitragsbemessungsgrenze from '../components/Beitragsbemessungsgrenze.vue'
import Beitragsentwicklung from '../components/Beitragsentwicklung.vue'
import Kontakt from '../components/Kontakt.vue'
import Datenschutz from '../components/Datenschutz.vue'
import VVariante from '../components/V-Variante.vue'
import JAEG from '../components/JAEG.vue'
import Arbeitgeberanteil from '../components/Arbeitgeberanteil.vue'
import Zusatzbeitrag from '../components/Zusatzbeitrag.vue'
import Kinder from '../components/Kinder.vue'
import Vergleich from '../components/Vergleich.vue'
import Blaek from '../components/Blaek.vue'
import Steuer from '../components/Steuer.vue'
import MB100 from '../components/MB100.vue'
import MP100 from '../components/MP100.vue'
import MP100vsMB100 from '../components/MP100vsMB100.vue'
import Zinsen from '../components/zahlen/Zinsen.vue'
import RfB from '../components/RfB.vue'
import Leistungen from '../components/Leistungen.vue'
import VPs from '../components/zahlen/VPs.vue'
import Gewinn from '../components/zahlen/Gewinn.vue'
import Gesundheitspruefung from '../components/Gesundheitspruefung.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/gesundheitskosten',
    name: 'Gesundheitskosten',
    component: Gesundheitskosten,
  },
  {
    path: '/beitragsbemessungsgrenze',
    name: 'Beitragsbemessungsgrenze',
    component: Beitragsbemessungsgrenze,
  },
  {
    path: '/beitragsentwicklung',
    name: 'Beitragsentwicklung',
    component: Beitragsentwicklung,
  },
  {
    path: '/jaeg',
    name: 'Jahresarbeitsentgeltgrenze',
    component: JAEG,
  },
  {
    path: '/arbeitgeberanteil',
    name: 'Arbeitgeberanteil',
    component: Arbeitgeberanteil,
  },
  {
    path: '/zusatzbeitrag',
    name: 'Zusatzbeitrag',
    component: Zusatzbeitrag,
  },
  {
    path: '/kinder',
    name: 'Kinder',
    component: Kinder,
  },
  {
    path: '/vergleich',
    name: 'Vergleich',
    component: Vergleich,
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },
  {
    path: '/BLAEK',
    name: 'Blaek',
    component: Blaek,
  },
  {
    path: '/steuer',
    name: 'Steuer',
    component: Steuer,
  },
  {
    path: '/mb100',
    name: 'MB100',
    component: MB100,
  },
  {
    path: '/mp100',
    name: 'MP100',
    component: MP100,
  },
  {
    path: '/mp100vsmb100',
    name: 'MP100vsMB100',
    component: MP100vsMB100,
  },
  {
    path: '/zinsen',
    name: 'Zinsen',
    component: Zinsen,
  },
  {
    path: '/rfb',
    name: 'RfB',
    component: RfB,
  },
  {
    path: '/leistungen',
    name: 'Leistungen',
    component: Leistungen,
  },
  {
    path: '/vps',
    name: 'VPs',
    component: VPs,
  },
  {
    path: '/gewinn',
    name: 'Gewinn',
    component: Gewinn,
  },
  {
    path: '/gp',
    name: 'Gesundheitspruefung',
    component: Gesundheitspruefung,
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz,
  },
  {
    path: '/v-variante',
    name: 'VVariante',
    component: VVariante,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
