import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_vm._v(" GKV vs PKV "),_c('p'),_c(VRow,[_c(VCol,{staticClass:"px-4"},[_c(VRangeSlider,{staticClass:"align-center",attrs:{"hint":"Bitte geben Sie ihr Alter und die Laufzeit an","max":_vm.max,"min":_vm.min,"thumb-label":"always","hide-details":"","persistent-hint":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VTextField,{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.range[0],"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.range[0]),callback:function ($$v) {_vm.$set(_vm.range, 0, $$v)},expression:"range[0]"}})]},proxy:true},{key:"append",fn:function(){return [_c(VTextField,{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"value":_vm.range[1],"hide-details":"","single-line":"","type":"number"},model:{value:(_vm.range[1]),callback:function ($$v) {_vm.$set(_vm.range, 1, $$v)},expression:"range[1]"}})]},proxy:true}]),model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)],1),_vm._v(" Aktueller Beitrag: "+_vm._s(_vm.beitragStart)+" "),_c('p',[_vm._v("Laufzeit: "+_vm._s(_vm.runtime)+" Jahre")]),_c(VTextField,{staticStyle:{"width":"250px"},attrs:{"type":"number","outlined":"","label":"Beitragssteigerung pro Jahr in %","step":".1"},model:{value:(_vm.zins),callback:function ($$v) {_vm.zins=_vm._n($$v)},expression:"zins"}}),_c('p',[_vm._v("Zins: "+_vm._s(_vm.zins)+" %")]),_c('p'),_c('p'),_c('p',[_vm._v(" Beitrag über Gesamtlaufzeit: "+_vm._s(_vm._f("toEUR")(_vm.totalAmount))+" EUR "),_c('highcharts',{attrs:{"options":_vm.chartOptions}})],1),_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-desc":true,"headers":_vm.headers,"items":_vm.dataTables,"items-per-page":100}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }