export const jaeg_datatables = [
  {
    year: 1970,
    jaeg_yearly: 7368,
    jaeg_monthly: 614,
    spread_yearly_absolute: 0,
    spread_monthly_absolute: 0,
    spread_procentual: 0,
  },
  {
    year: 1971,
    jaeg_yearly: 8748,
    jaeg_monthly: 729,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 18.73,
  },
  {
    year: 1972,
    jaeg_yearly: 9660,
    jaeg_monthly: 805,
    spread_yearly_absolute: 912,
    spread_monthly_absolute: 76,
    spread_procentual: 10.43,
  },
  {
    year: 1973,
    jaeg_yearly: 10584,
    jaeg_monthly: 882,
    spread_yearly_absolute: 924,
    spread_monthly_absolute: 77,
    spread_procentual: 9.57,
  },
  {
    year: 1974,
    jaeg_yearly: 11508,
    jaeg_monthly: 959,
    spread_yearly_absolute: 924,
    spread_monthly_absolute: 77,
    spread_procentual: 8.73,
  },
  {
    year: 1975,
    jaeg_yearly: 12888,
    jaeg_monthly: 1074,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 11.99,
  },
  {
    year: 1976,
    jaeg_yearly: 14268,
    jaeg_monthly: 1189,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 10.71,
  },
  {
    year: 1977,
    jaeg_yearly: 15648,
    jaeg_monthly: 1304,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 9.67,
  },
  {
    year: 1978,
    jaeg_yearly: 17028,
    jaeg_monthly: 1419,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 8.82,
  },
  {
    year: 1979,
    jaeg_yearly: 18408,
    jaeg_monthly: 1534,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 8.1,
  },
  {
    year: 1980,
    jaeg_yearly: 19332,
    jaeg_monthly: 1611,
    spread_yearly_absolute: 924,
    spread_monthly_absolute: 77,
    spread_procentual: 5.02,
  },
  {
    year: 1981,
    jaeg_yearly: 20247,
    jaeg_monthly: 1687.25,
    spread_yearly_absolute: 915,
    spread_monthly_absolute: 76.25,
    spread_procentual: 4.73,
  },
  {
    year: 1982,
    jaeg_yearly: 21628,
    jaeg_monthly: 1802.33,
    spread_yearly_absolute: 1381,
    spread_monthly_absolute: 115.08,
    spread_procentual: 6.82,
  },
  {
    year: 1983,
    jaeg_yearly: 23008,
    jaeg_monthly: 1917.33,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115.0,
    spread_procentual: 6.38,
  },
  {
    year: 1984,
    jaeg_yearly: 23928,
    jaeg_monthly: 1994,
    spread_yearly_absolute: 920,
    spread_monthly_absolute: 76.67,
    spread_procentual: 4.0,
  },
  {
    year: 1985,
    jaeg_yearly: 24849,
    jaeg_monthly: 2070.75,
    spread_yearly_absolute: 921,
    spread_monthly_absolute: 76.75,
    spread_procentual: 3.85,
  },
  {
    year: 1986,
    jaeg_yearly: 25769,
    jaeg_monthly: 2147.42,
    spread_yearly_absolute: 920,
    spread_monthly_absolute: 76.67,
    spread_procentual: 3.7,
  },
  {
    year: 1987,
    jaeg_yearly: 26229,
    jaeg_monthly: 2185.75,
    spread_yearly_absolute: 460,
    spread_monthly_absolute: 38.33,
    spread_procentual: 1.79,
  },
  {
    year: 1988,
    jaeg_yearly: 27610,
    jaeg_monthly: 2300.83,
    spread_yearly_absolute: 1381,
    spread_monthly_absolute: 115.08,
    spread_procentual: 5.27,
  },
  {
    year: 1989,
    jaeg_yearly: 28070,
    jaeg_monthly: 2339.17,
    spread_yearly_absolute: 460,
    spread_monthly_absolute: 38.33,
    spread_procentual: 1.67,
  },
  {
    year: 1990,
    jaeg_yearly: 28990,
    jaeg_monthly: 2415.83,
    spread_yearly_absolute: 920,
    spread_monthly_absolute: 76.67,
    spread_procentual: 3.28,
  },
  {
    year: 1991,
    jaeg_yearly: 29911,
    jaeg_monthly: 2492.58,
    spread_yearly_absolute: 921,
    spread_monthly_absolute: 76.75,
    spread_procentual: 3.18,
  },
  {
    year: 1992,
    jaeg_yearly: 31291,
    jaeg_monthly: 2607.58,
    spread_yearly_absolute: 1380,
    spread_monthly_absolute: 115,
    spread_procentual: 4.61,
  },
  {
    year: 1993,
    jaeg_yearly: 33132,
    jaeg_monthly: 2761,
    spread_yearly_absolute: 1841,
    spread_monthly_absolute: 153.42,
    spread_procentual: 5.88,
  },
  {
    year: 1994,
    jaeg_yearly: 34972,
    jaeg_monthly: 2914.33,
    spread_yearly_absolute: 1840,
    spread_monthly_absolute: 153.33,
    spread_procentual: 5.55,
  },
  {
    year: 1995,
    jaeg_yearly: 35893,
    jaeg_monthly: 2991.08,
    spread_yearly_absolute: 921,
    spread_monthly_absolute: 76.75,
    spread_procentual: 2.63,
  },
  {
    year: 1996,
    jaeg_yearly: 36813,
    jaeg_monthly: 3067.75,
    spread_yearly_absolute: 920,
    spread_monthly_absolute: 76.67,
    spread_procentual: 2.56,
  },
  {
    year: 1997,
    jaeg_yearly: 37733,
    jaeg_monthly: 3144.42,
    spread_yearly_absolute: 920,
    spread_monthly_absolute: 76.67,
    spread_procentual: 2.5,
  },
  {
    year: 1998,
    jaeg_yearly: 38654,
    jaeg_monthly: 3221.17,
    spread_yearly_absolute: 921,
    spread_monthly_absolute: 76.75,
    spread_procentual: 2.44,
  },
  {
    year: 1999,
    jaeg_yearly: 39114,
    jaeg_monthly: 3259.5,
    spread_yearly_absolute: 460,
    spread_monthly_absolute: 38.33,
    spread_procentual: 1.19,
  },
  {
    year: 2000,
    jaeg_yearly: 39574,
    jaeg_monthly: 3297.83,
    spread_yearly_absolute: 460,
    spread_monthly_absolute: 38.33,
    spread_procentual: 1.18,
  },
  {
    year: 2001,
    jaeg_yearly: 40034,
    jaeg_monthly: 3336.17,
    spread_yearly_absolute: 460,
    spread_monthly_absolute: 38.33,
    spread_procentual: 1.16,
  },
  {
    year: 2002,
    jaeg_yearly: 40500,
    jaeg_monthly: 3375,
    spread_yearly_absolute: 466,
    spread_monthly_absolute: 38.83,
    spread_procentual: 1.16,
  },
  {
    year: 2003,
    jaeg_yearly: 45900,
    jaeg_monthly: 3825,
    spread_yearly_absolute: 5400,
    spread_monthly_absolute: 450.0,
    spread_procentual: 13.33,
  },
  {
    year: 2004,
    jaeg_yearly: 46350,
    jaeg_monthly: 3862.5,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.98,
  },
  {
    year: 2005,
    jaeg_yearly: 46800,
    jaeg_monthly: 3900,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.97,
  },
  {
    year: 2006,
    jaeg_yearly: 47250,
    jaeg_monthly: 3937.5,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.96,
  },
  {
    year: 2007,
    jaeg_yearly: 47700,
    jaeg_monthly: 3975,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.95,
  },
  {
    year: 2008,
    jaeg_yearly: 48150,
    jaeg_monthly: 4012.5,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.94,
  },
  {
    year: 2009,
    jaeg_yearly: 48600,
    jaeg_monthly: 4050,
    spread_yearly_absolute: 450,
    spread_monthly_absolute: 37.5,
    spread_procentual: 0.93,
  },
  {
    year: 2010,
    jaeg_yearly: 49950,
    jaeg_monthly: 4162.5,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.78,
  },
  {
    year: 2011,
    jaeg_yearly: 49500,
    jaeg_monthly: 4125,
    spread_yearly_absolute: -450,
    spread_monthly_absolute: -37.5,
    spread_procentual: -0.9,
  },
  {
    year: 2012,
    jaeg_yearly: 50850,
    jaeg_monthly: 4237.5,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.73,
  },
  {
    year: 2013,
    jaeg_yearly: 52200,
    jaeg_monthly: 4350,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.65,
  },
  {
    year: 2014,
    jaeg_yearly: 53550,
    jaeg_monthly: 4462.5,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.59,
  },
  {
    year: 2015,
    jaeg_yearly: 54900,
    jaeg_monthly: 4575,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.52,
  },
  {
    year: 2016,
    jaeg_yearly: 56250,
    jaeg_monthly: 4687.5,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.46,
  },
  {
    year: 2017,
    jaeg_yearly: 57600,
    jaeg_monthly: 4800,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.4,
  },
  {
    year: 2018,
    jaeg_yearly: 59400,
    jaeg_monthly: 4950,
    spread_yearly_absolute: 1800,
    spread_monthly_absolute: 150,
    spread_procentual: 3.13,
  },
  {
    year: 2019,
    jaeg_yearly: 60750,
    jaeg_monthly: 5062.5,
    spread_yearly_absolute: 1350,
    spread_monthly_absolute: 112.5,
    spread_procentual: 2.27,
  },
  {
    year: 2020,
    jaeg_yearly: 62550,
    jaeg_monthly: 5212.5,
    spread_yearly_absolute: 1800,
    spread_monthly_absolute: 150,
    spread_procentual: 2.96,
  },
  {
    year: 2021,
    jaeg_yearly: 64350,
    jaeg_monthly: 5362.5,
    spread_yearly_absolute: 1800,
    spread_monthly_absolute: 150,
    spread_procentual: 2.88,
  },
  {
    year: 2022,
    jaeg_yearly: 64350,
    jaeg_monthly: 5362.5,
    spread_yearly_absolute: 0,
    spread_monthly_absolute: 0,
    spread_procentual: 0,
  },
  {
    year: 2023,
    jaeg_yearly: 66600,
    jaeg_monthly: 5550,
    spread_yearly_absolute: 2250,
    spread_monthly_absolute: 187.5,
    spread_procentual: 3.5,
  },
  {
    year: 2024,
    jaeg_yearly: 69300,
    jaeg_monthly: 5775,
    spread_yearly_absolute: 2700,
    spread_monthly_absolute: 225,
    spread_procentual: 4.05,
  },
  {
    year: 2025,
    jaeg_yearly: 73800,
    jaeg_monthly: 6150,
    spread_yearly_absolute: 4500,
    spread_monthly_absolute: 375,
    spread_procentual: 6.49,
  },
]
