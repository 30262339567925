<template>
  <v-container>
    Jahresarbeitsentgeltgrenze (JAEG)
    <p></p>
    Die Jahresarbeitsentgeltgrenze (JAEG) bestimmt ab wann Sie als Angestellte/r versicherungsfrei in der gesetzlichen Krankenversicherung (GKV) sind. Übersteigt Ihr Bruttoeinkommen diese Grenze,
    endet die Versicherungspflicht in der GKV und Sie werden freiwilliges Mitglied in der GKV. Das bedeutet auch, dass Sie ab diesem Zeitpunkt in die private Krankenversicherung wechseln können. Die
    JAEG ändert sich zum 1. Januar im Verhälntis der Lohnsteigerungen in Deutschland. Die Änderung wird durch das Bundesministerium für Arbeit und Soziales verordnet. Die JAEG wird oft mit der
    Beitragsbemessungsgrenze verwechselt.
    <highcharts :options="chartOptions"></highcharts>
    <p>
      <v-data-table sort-by="year" :sort-desc="true" :headers="headers" :items="jaegs" :items-per-page="100" class="elevation-1">
        <template v-slot:[`item.year`]="{ item }">
          <b>{{ item.year }}</b>
        </template>
        <template v-slot:[`item.jaeg_yearly`]="{ item }">
          <v-chip color="#95CEFF"> {{ formatCurrency(item.jaeg_yearly) }}</v-chip>
        </template>
        <template v-slot:[`item.jaeg_monthly`]="{ item }">
          <v-chip color="lightgrey"> {{ formatCurrency(item.jaeg_monthly) }}</v-chip>
        </template>
        <template v-slot:[`item.spread_yearly_absolute`]="{ item }">
          {{ formatCurrency(item.spread_yearly_absolute) }}
        </template>
        <template v-slot:[`item.spread_monthly_absolute`]="{ item }">
          {{ formatCurrency(item.spread_monthly_absolute) }}
        </template>
        <template v-slot:[`item.spread_procentual`]="{ item }">
          <v-chip :color="getColor(item.spread_procentual)" dark>{{ toPercent(item.spread_procentual, 2) }}</v-chip>
        </template>
      </v-data-table>
    </p>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { jaeg_monthly } from './data/beitragsbemessungsgrenze/jaeg_monthly'
import { jaeg_yearly } from './data/beitragsbemessungsgrenze/jaeg_yearly'
import { jaeg_datatables } from './data/jaeg_datatables'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Jahresarbeitsentgeltgrenze (JAEG) von 1970 bis 2025',
        },
        subtitle: {
          text: 'Quelle: Allianz Reith</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'JAEG (monatlich)',
            },
          },
          //RIGHT
          {
            title: {
              text: 'JAEG (jährlich)',
            },
            opposite: true,
            min: 5000,
            max: 70000,
          },
          //RIGHT
          {
            title: {
              text: 'Prozentualer Anstieg Vorjahr',
            },
            opposite: true,
            min: -1,
            max: 5,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'JAEG (monatlich)',
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: jaeg_monthly,
          },
          {
            type: 'column',
            name: 'JAEG (jährlich)',
            yAxis: 1,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: jaeg_yearly,
          },
          {
            type: 'spline',
            name: 'Anstieg zum Vorjahr (prozentual)',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2025, 6.49],
              [2024, 4.05],
              [2023, 3.5],
              [2022, 0.0],
              [2021, 2.88],
              [2020, 2.96],
              [2019, 2.27],
              [2018, 3.13],
              [2017, 2.4],
              [2016, 2.46],
              [2015, 2.52],
              [2014, 2.59],
              [2013, 2.65],
              [2012, 2.73],
              [2011, -0.9],
              [2010, 2.78],
              [2009, 0.93],
              [2008, 0.94],
              [2007, 0.95],
              [2006, 0.96],
              [2005, 0.97],
              [2004, 0.98],
              [2003, 13.33],
              [2002, 1.16],
              [2001, 1.16],
              [2000, 1.18],
              [1999, 1.19],
              [1998, 2.44],
              [1997, 2.5],
              [1996, 2.56],
              [1995, 2.63],
              [1994, 5.55],
              [1993, 5.88],
              [1992, 4.61],
              [1991, 3.18],
              [1990, 3.28],
              [1989, 1.67],
              [1988, 5.27],
              [1987, 1.79],
              [1986, 3.7],
              [1985, 3.85],
              [1984, 4.0],
              [1983, 6.38],
              [1982, 6.82],
              [1981, 4.73],
              [1980, 5.02],
              [1979, 8.1],
              [1978, 8.82],
              [1977, 9.67],
              [1976, 10.71],
              [1975, 11.99],
              [1974, 8.73],
              [1973, 9.57],
              [1972, 10.43],
              [1971, 18.73],
              [1970, 0.0],
            ],
          },
        ],
      },

      headers: [
        {
          text: 'Jahr',
          align: 'start',
          sortable: true,
          value: 'year',
        },
        { text: 'JAEG (jährlich)', value: 'jaeg_yearly' },
        { text: 'JAEG (monatlich)', value: 'jaeg_monthly' },
        {
          text: 'Diff. zu Vorjahr (jährlich absolut)',
          value: 'spread_yearly_absolute',
        },
        {
          text: 'Diff. zu Vorjahr (monatlich absolut)',
          value: 'spread_monthly_absolute',
        },
        { text: 'Diff. zu Vorjahr (prozentual)', value: 'spread_procentual' },
      ],
      jaegs: jaeg_datatables,
    }
  },
  created: function() {
    //this.getPercentage()
  },
  methods: {
    getPercentage: function() {
      //console.log(jaeg_datatables)
    },
    getColor(spread_procentual) {
      if (spread_procentual > 10) return 'red'
      else if (spread_procentual > 5) return 'orange'
      else return 'green'
    },
    formatCurrency(item) {
      //console.log(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item))
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(item)
    },
    toPercent(item, float) {
      const percent = parseFloat(item * 1).toFixed(float) + ' %'
      return percent
    },
  },
}
</script>

<style></style>
