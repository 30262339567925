import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('highcharts',{attrs:{"options":_vm.chartOptions,"updateArgs":_vm.updateArgs}}),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm._f("toEUR")(_vm.vPriceComputed))+" Mehrbeitrag für "+_vm._s(_vm._f("toEUR")(this.vEntlastung))+" EUR Entlastung ab 65."),_c('br'),(_vm.vMax)?_c('span',[_vm._v("vMax: "+_vm._s(_vm._f("toEUR")(_vm.vMax)))]):_vm._e()]),_c('p',{staticStyle:{"height":"30px"}}),_c(VSlider,{attrs:{"step":"5","label":"Entlastung ab 65","color":"blue","thumb-label":"always","track-color":"grey","min":"0","max":_vm.vMax,"append-icon":"mdi-plus","prepend-icon":"mdi-minus"},on:{"click:append":_vm.vPlus,"click:prepend":_vm.vMinus},model:{value:(_vm.vEntlastung),callback:function ($$v) {_vm.vEntlastung=$$v},expression:"vEntlastung"}}),_c(VCard,{staticClass:"mx-auto my-2 pa-10",attrs:{"outlined":"","elevation":"1","max-width":_vm.$vuetify.breakpoint.xs ? 350 : 500}},[_c(VRadioGroup,{attrs:{"row":""},model:{value:(_vm.geschlecht),callback:function ($$v) {_vm.geschlecht=$$v},expression:"geschlecht"}},[_c(VRadio,{attrs:{"label":"männlich","value":"M"}}),_c(VRadio,{attrs:{"label":"weiblich","value":"W"}}),_c(VRadio,{attrs:{"label":"unisex","value":"U"}})],1),_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scroll-y-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"value":_vm.computedGeburtsdatum,"outlined":"","label":"Geburtsdatum","readonly":""}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{ref:"picker",attrs:{"locale":"de","transition":"scroll-y-transition","max":new Date(new Date().setFullYear(new Date().getFullYear() - 21)).toISOString().substr(0, 10),"min":new Date(new Date().setFullYear(new Date().getFullYear() - 59)).toISOString().substr(0, 10)},on:{"change":_vm.save},model:{value:(_vm.geburtsdatum),callback:function ($$v) {_vm.geburtsdatum=$$v},expression:"geburtsdatum"}})],1),_c(VSelect,{staticStyle:{"width":"200px"},attrs:{"items":_vm.items,"label":"Alter","outlined":""},model:{value:(_vm.alter),callback:function ($$v) {_vm.alter=$$v},expression:"alter"}}),_c(VTextField,{staticStyle:{"width":"250px"},attrs:{"type":"number","outlined":"","label":"aktueller KV Beitrag"},model:{value:(_vm.aktuellerKVBeitrag),callback:function ($$v) {_vm.aktuellerKVBeitrag=_vm._n($$v)},expression:"aktuellerKVBeitrag"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }