<template>
  <div>
    {{ eheStatus }}

    <!-- VERHEIRATET ODER UNVERHEIRATET -->
    <div class="subtitle-2 font-weight-bold">
      Sind Sie verheiratet oder unverheiratet?:
    </div>
    <v-chip-group v-model="eheStatus" :mandatory="mandatory" column>
      <v-chip type="text" filter value="Verheiratet" outlined>
        Verheiratet
      </v-chip>
      <v-chip type="text" filter value="Unverheiratet" outlined>
        Unverheirated
      </v-chip>
    </v-chip-group>
    <div
      v-if="eheStatus == 'Unverheiratet'"
      class="subtitle-2 font-weight-bold"
    >
      <!-- BEI UNVERHEIRATETEN -->
      <!-- WIE IST DIE MUTTER VERSICHERT -->
      Die Mutter ist:
    </div>
    <v-chip-group
      v-if="eheStatus == 'Unverheiratet'"
      v-model="versicherungMutter"
      :mandatory="mandatory"
      column
    >
      <v-chip type="text" filter value="PKV" outlined>
        Mutter ist PKV versichert
      </v-chip>
      <v-chip type="text" filter value="GKV" outlined>
        Mutter ist gesetzlich versichert
      </v-chip>
    </v-chip-group>

    <div v-if="eheStatus == 'Verheiratet'" class="subtitle-2 font-weight-bold">
      <!-- BEI VERHEIRATETEN -->
      <!-- WIE IST DIE MUTTER VERSICHERT -->
      Die Mutter ist:
    </div>
    <v-chip-group
      v-if="eheStatus == 'Verheiratet'"
      v-model="versicherungMutter"
      :mandatory="mandatory"
      column
    >
      <v-chip type="text" filter value="PKV" outlined>
        Mutter ist PKV versichert
      </v-chip>
      <v-chip type="text" filter value="GKV" outlined>
        Mutter ist gesetzlich versichert
      </v-chip>
    </v-chip-group>

    <div v-if="eheStatus == 'Verheiratet'" class="subtitle-2 font-weight-bold">
      <!-- BEI VERHEIRATETEN -->
      <!-- WIE IST DER VATER VERSICHERT -->
      Der Vater ist:
    </div>
    <v-chip-group
      v-if="eheStatus == 'Verheiratet'"
      v-model="versicherungVater"
      :mandatory="mandatory"
      column
    >
      <v-chip type="text" filter value="PKV" outlined>
        Vater ist PKV versichert
      </v-chip>
      <v-chip type="text" filter value="GKV" outlined>
        Vater ist gesetzlich versichert
      </v-chip>
    </v-chip-group>

    <div
      v-if="
        eheStatus == 'Verheiratet' &&
          versicherungMutter == 'PKV' &&
          versicherungVater == 'GKV'
      "
      class="subtitle-2 font-weight-bold"
    >
      <!-- BEI PKV VERDIENT DIE MUTTER UEBER ODER UNTER JAEG -->
      Die Mutter ist in der PKV und verdient:
    </div>
    <v-chip-group
      v-if="
        eheStatus == 'Verheiratet' &&
          versicherungMutter == 'PKV' &&
          versicherungVater == 'GKV'
      "
      v-model="mutterStatusJAEG"
      :mandatory="mandatory"
      column
    >
      <v-chip type="text" filter value="ueberJAEG" outlined>
        überhalb der JAEG ({{ $store.state.aktuelleJAEG }} EUR)
      </v-chip>
      <v-chip type="text" filter value="unterJAEG" outlined>
        unterhalb der JAEG ({{ $store.state.aktuelleJAEG }} EUR))
      </v-chip>
    </v-chip-group>

    <!-- BEI EHEMANN GKV ER IST PFLICHTIG ODER FREIWILLIG ÜBER ODER UNTER JAEG -->
    <div
      v-if="
        eheStatus == 'Verheiratet' &&
          versicherungMutter == 'PKV' &&
          versicherungVater == 'GKV'
      "
      class="subtitle-2 font-weight-bold"
    >
      Der Vater ist in der GKV:
    </div>
    <v-chip-group
      v-if="
        eheStatus == 'Verheiratet' &&
          versicherungMutter == 'PKV' &&
          versicherungVater == 'GKV'
      "
      v-model="vaterStatusGKV"
      :mandatory="mandatory"
      column
    >
      <v-chip type="text" filter value="vaterPflichtversichert" outlined>
        Pflichtversichert
      </v-chip>
      <v-chip type="text" filter value="vaterFreiwilligUeberJAEG" outlined>
        Freiwillig über JAEG
      </v-chip>
      <v-chip type="text" filter value="vaterFreiwilligUnterJAEG" outlined>
        Freiwillig unter JAEG
      </v-chip>
    </v-chip-group>

    <!-- ERGENBISSE -->

    <!-- ERGEBNIS UNVERHEIRATET MUTTER PKV -->
    <v-alert
      v-if="versicherungMutter == 'PKV' && eheStatus == 'Unverheiratet'"
      color="primary"
      dark
      icon="mdi-account-child-outline"
      border="left"
      prominent
    >
      Ihr Baby wird privat versichert Da Sie privat versichert sind, muss auch
      das Kind privat versichert werden. Sie können Ihr Kind im Rahmen der
      Kindernachversicherung in Ihrem eigenen Tarif mit anmelden
    </v-alert>

    <!-- ERGEBNIS UNVERHEIRATET MUTTER GKV -->
    <v-alert
      v-if="versicherungMutter == 'GKV' && eheStatus == 'Unverheiratet'"
      color="primary"
      dark
      icon="mdi-account-child-outline"
      border="left"
      prominent
    >
      Ihr Baby wird gesetzlich versichert Da Sie gesetzlich versichert sind, ist
      auch das Kind in der gesetzlichen Familienversicherung beitragsfrei mit
      versichert. Hier ist unerheblich, ob Sie freiwillig oder pflichtversichert
      sind. Wir empfehlen jedoch, für einen optimalen Gesundheitsschutz des
      Kindes eine private Zusatzversicherung abzuschließen.
    </v-alert>

    <!-- ERGEBNIS VERHEIRATET MUTTER PKV VATER PKV -->
    <v-alert
      v-if="
        versicherungMutter == 'PKV' &&
          versicherungVater == 'PKV' &&
          eheStatus == 'Verheiratet'
      "
      color="primary"
      dark
      icon="mdi-account-child-outline"
      border="left"
      prominent
    >
      Da Sie privat versichert sind, muss auch das Kind privat versichert
      werden. Bei welcher Ihrer beiden Versicherungen können Sie sich anhand der
      Leistungen und des Beitrags frei aussuchen.
    </v-alert>

    <!-- ERGEBNIS VERHEIRATET MUTTER GKV VATER GKV -->
    <v-alert
      v-if="
        versicherungMutter == 'GKV' &&
          versicherungVater == 'GKV' &&
          eheStatus == 'Verheiratet'
      "
      color="primary"
      dark
      icon="mdi-account-child-outline"
      border="left"
      prominent
    >
      Beide gesetzlich – auch das Kind gesetzlich Da Sie gesetzlich versichert
      sind, ist auch das Kind in der gesetzlichen Familienversicherung
      beitragsfrei mit versichert. Hier ist unerheblich, ob Sie freiwillig oder
      pflichtversichert sind.
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mandatory: false,
      eheStatus: null,
      versicherungMutter: null,
      versicherungVater: null,
      mutterStatusJAEG: null,
      vaterStatusGKV: null,
    };
  },
};
</script>
<style></style>
