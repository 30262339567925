<template>
  <v-container>
    Versicherte Personen
    <p></p>

    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Versicherte Personen von 2011 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.allianz.de/" target="_blank">APKV Geschäftsberichte</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Versicherte Personen Gesamt',
            },
            min: 0,
            max: 3000000,
          },

          //RIGHT
          {
            title: {
              text: 'Versicherte Personen Zusatzversicherung',
            },
            opposite: true,
            min: 0,
            max: 3000000,
          },
          //RIGHT
          {
            title: {
              text: 'Versicherte Personen Krankenvollversicherung',
            },
            min: 0,
            max: 3000000,
            opposite: true,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Versicherte Personen gesamt',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' Personen',
            },
            data: [
              [2011, 2457000],
              [2012, 2524929],
              [2013, 2562739],
              [2014, 2575454],
              [2015, 2588748],
              [2016, 2617889],
              [2017, 2910978],
              [2018, 2925015],
              [2019, 2714348],
              [2020, 2756367],
              [2021, 2794821],
              [2022, 2846948],
              [2023, 2948565],
            ],
            // sample data
          },

          {
            type: 'column',
            name: 'Versicherte Personen Zusatzversicherung',
            yAxis: 2,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' Personen',
            },
            data: [
              [2012, 1853497],
              [2013, 1908365],
              [2014, 1934881],
              [2015, 1960811],
              [2016, 1999572],
              [2017, 2022156],
              [2018, 2054487],
              [2019, 2123499],
              [2020, 2174828],
              [2021, 2221155],
              [2022, 2282156],
              [2023, 2390339],
            ],
          },
          {
            type: 'column',
            name: 'Versicherte Personen Krankenvollversicherung',
            yAxis: 1,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' Personen',
            },
            data: [
              [2012, 671432],
              [2013, 654374],
              [2014, 640573],
              [2015, 627937],
              [2016, 618317],
              [2017, 611631],
              [2018, 601016],
              [2019, 590849],
              [2020, 581591],
              [2021, 573666],
              [2022, 564792],
              [2023, 558226],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
