import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_vm._v(" Jahresarbeitsentgeltgrenze (JAEG) "),_c('p'),_vm._v(" Die Jahresarbeitsentgeltgrenze (JAEG) bestimmt ab wann Sie als Angestellte/r versicherungsfrei in der gesetzlichen Krankenversicherung (GKV) sind. Übersteigt Ihr Bruttoeinkommen diese Grenze, endet die Versicherungspflicht in der GKV und Sie werden freiwilliges Mitglied in der GKV. Das bedeutet auch, dass Sie ab diesem Zeitpunkt in die private Krankenversicherung wechseln können. Die JAEG ändert sich zum 1. Januar im Verhälntis der Lohnsteigerungen in Deutschland. Die Änderung wird durch das Bundesministerium für Arbeit und Soziales verordnet. Die JAEG wird oft mit der Beitragsbemessungsgrenze verwechselt. "),_c('highcharts',{attrs:{"options":_vm.chartOptions}}),_c('p',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"sort-by":"year","sort-desc":true,"headers":_vm.headers,"items":_vm.jaegs,"items-per-page":100},scopedSlots:_vm._u([{key:"item.year",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.year))])]}},{key:"item.jaeg_yearly",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":"#95CEFF"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.jaeg_yearly)))])]}},{key:"item.jaeg_monthly",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":"lightgrey"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.jaeg_monthly)))])]}},{key:"item.spread_yearly_absolute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.spread_yearly_absolute))+" ")]}},{key:"item.spread_monthly_absolute",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.spread_monthly_absolute))+" ")]}},{key:"item.spread_procentual",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getColor(item.spread_procentual),"dark":""}},[_vm._v(_vm._s(_vm.toPercent(item.spread_procentual, 2)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }