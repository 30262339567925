import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,_vm._l((_vm.fragenVoll),function(value,name){return _c('div',{key:value,staticClass:"pa-4"},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":_vm.$vuetify.breakpoint.xs ? 350 : 550}},[_c(VCardTitle,[_vm._v(_vm._s(name)+" ")]),_c(VCardText,[_c('span',{staticClass:"body-1"},[_vm._v(_vm._s(value))])]),_c(VCardActions,[_c(VBtnToggle,{attrs:{"v-model":_vm.Antwort + 'name'}},[_c(VBtn,{attrs:{"large":"","block":""}},[_vm._v(" Ja ")]),_c(VBtn,{attrs:{"block":"","large":""}},[_vm._v(" Nein ")])],1)],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }