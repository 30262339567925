export const jaeg_yearly = [
  [1970, 7368],
  [1971, 8748],
  [1972, 9660],
  [1973, 10584],
  [1974, 11508],
  [1975, 12888],
  [1976, 14268],
  [1977, 15648],
  [1978, 17028],
  [1979, 18408],
  [1980, 19332],
  [1981, 20247],
  [1982, 21628],
  [1983, 23008],
  [1984, 23928],
  [1985, 24849],
  [1986, 25769],
  [1987, 26229],
  [1988, 27610],
  [1989, 28070],
  [1990, 28990],
  [1991, 29911],
  [1992, 31291],
  [1993, 33132],
  [1994, 34972],
  [1995, 35893],
  [1996, 36813],
  [1997, 37733],
  [1998, 38654],
  [1999, 39114],
  [2000, 39574],
  [2001, 40034],
  [2002, 40500],
  [2003, 45900],
  [2004, 46350],
  [2005, 46800],
  [2006, 47250],
  [2007, 47700],
  [2008, 48150],
  [2009, 48600],
  [2010, 49950],
  [2011, 49500],
  [2012, 50850],
  [2013, 52200],
  [2014, 53550],
  [2015, 54900],
  [2016, 56250],
  [2017, 57600],
  [2018, 59400],
  [2019, 60750],
  [2020, 62550],
  [2021, 64350],
  [2022, 64350],
  [2023, 66600],
  [2024, 69300],
  [2025, 73800],
]
