export const zusatzbeitrag_datatables = [
  {
    year: 2025,
    zusatzbeitrag: 2.45,
    bbg: 5512.5,
    zusatzbeitrag_absolute: 135.06,
    zusatzbeitrag_procentual_spread: 44.12,
  },
  {
    year: 2024,
    zusatzbeitrag: 1.7,
    bbg: 5175,
    zusatzbeitrag_absolute: 87.98,
    zusatzbeitrag_procentual_spread: 7,
  },
  {
    year: 2023,
    zusatzbeitrag: 1.6,
    bbg: 4987.5,
    zusatzbeitrag_absolute: 79.8,
    zusatzbeitrag_procentual_spread: 23,
  },
  {
    year: 2022,
    zusatzbeitrag: 1.3,
    bbg: 4837.5,
    zusatzbeitrag_absolute: 62.89,
    zusatzbeitrag_procentual_spread: 0,
  },
  {
    year: 2021,
    zusatzbeitrag: 1.3,
    bbg: 4837.5,
    zusatzbeitrag_absolute: 62.89,
    zusatzbeitrag_procentual_spread: 18,
  },
  {
    year: 2020,
    zusatzbeitrag: 1.1,
    bbg: 4687.5,
    zusatzbeitrag_absolute: 51.56,
    zusatzbeitrag_procentual_spread: 22,
  },
  {
    year: 2019,
    zusatzbeitrag: 0.9,
    bbg: 4537.5,
    zusatzbeitrag_absolute: 40.84,
    zusatzbeitrag_procentual_spread: -10,
  },
  {
    year: 2018,
    zusatzbeitrag: 1.0,
    bbg: 4425.0,
    zusatzbeitrag_absolute: 44.25,
    zusatzbeitrag_procentual_spread: -9,
  },
  {
    year: 2017,
    zusatzbeitrag: 1.1,
    bbg: 4350.0,
    zusatzbeitrag_absolute: 47.85,
    zusatzbeitrag_procentual_spread: 0,
  },
  {
    year: 2016,
    zusatzbeitrag: 1.1,
    bbg: 4237.5,
    zusatzbeitrag_absolute: 46.61,
    zusatzbeitrag_procentual_spread: 22,
  },
  {
    year: 2015,
    zusatzbeitrag: 0.9,
    bbg: 4125.0,
    zusatzbeitrag_absolute: 37.13,
    zusatzbeitrag_procentual_spread: 0,
  },
]
