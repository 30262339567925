<template>
  <v-container>
    <span class="text-h6">Der Arbeitgeberanteil</span>
    <p></p>
    Der Arbeitgeber (AG) beteiligt sich bei Angestellten und dessen mitversicherten Familienangehörigen mit 50 % bis zu einem Höchstbetrag an den tatsächlichen Kosten zur Kranken- und
    Pflegeversicherung.
    <p></p>
    Diese Regelung gilt für privat und gesetzlich Versicherte gleichermaßen. Er wird im Sozialgesetzbuch (SGB V) geregelt. Der Höchstbetrag wird 2025 wie folgt errechnet: BBG (5512,50 EUR) x 14,6 %
    (KV) = 804,83 EUR + Zusatzbeitrag (2,45%) = 135,06 EUR = 939,88 EUR + 3,4% (PV) = 187,43 EUR = 1127,31 EUR (davon die Hälfte).
    <p></p>
    Seit 2019 wird der Zusatzbeitrag wieder paritätisch finanziert – je zur Hälfte von Arbeitgeber und Arbeitnehmer.
    <p></p>
    <v-alert tonal text="" type="success" variant="outlined">Der Arbeitgeberanteil hat fast immer einen positiven Effekt bei Beitragsanpassungen. Siehe AG Delta Vorjahr</v-alert>
    <highcharts :options="chartOptions"></highcharts>
    <p>
      <v-data-table sort-by="year" :sort-desc="true" :headers="headers" :items="arbeitgeberbeitraege" :items-per-page="100" class="elevation-1">
        <template v-slot:[`item.year`]="{ item }">
          <b>{{ item.year }}</b>
        </template>
        <template v-slot:[`item.max_KV`]="{ item }">
          <v-chip color="#95CEFF"
            ><span style="color: black">{{ formatCurrency(item.max_KV) }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.max_PV`]="{ item }">
          <v-chip color="grey"
            ><span style="color: black"
              ><span style="color: white">{{ formatCurrency(item.max_PV) }}</span></span
            ></v-chip
          >
        </template>
        <template v-slot:[`item.anteil_AG_KV`]="{ item }">
          <v-chip color="#90ED7D"
            ><span style="color: #424242">{{ formatCurrency(item.anteil_AG_KV) }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.anteil_AG_PV`]="{ item }">
          <v-chip color="#f7a35c"
            ><span style="color: white">{{ formatCurrency(item.anteil_AG_PV) }}</span>
          </v-chip>
        </template>
        <template v-slot:[`item.delta_Vorjahr`]="{ item }">
          <v-chip color="#8085E9">
            <span style="color: white">{{ formatCurrency(item.delta_Vorjahr) }}</span></v-chip
          >
        </template>
      </v-data-table>
    </p>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { arbeitgeberanteil_datatables } from './data/arbeitgeberanteil/arbeitgeberanteil_datatables'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Entwicklung Arbeitgeberanteil von 2019 bis 2025',
        },
        subtitle: {
          text: 'Quelle: allianz.reith.de</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
          tickInterval: 1, // Force the x-axis to show whole years only
          allowDecimals: false, // Disable decimals on the x-axis
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Limit KV',
            },
          },
        ],

        series: [
          {
            type: 'spline',
            name: 'Limit KV',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: arbeitgeberanteil_datatables.map((item) => {
              return [item.year, item.max_KV]
            }),
          },
          {
            type: 'spline',
            name: 'Limit Pflege',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: arbeitgeberanteil_datatables.map((item) => {
              return [item.year, item.max_PV]
            }),
          },
          {
            type: 'spline',
            name: 'Arbeitgeberanteil KV',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: arbeitgeberanteil_datatables.map((item) => {
              return [item.year, item.anteil_AG_KV]
            }),
          },
          {
            type: 'spline',
            name: 'Arbeitgeberanteil Pflege',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: arbeitgeberanteil_datatables.map((item) => {
              return [item.year, item.anteil_AG_PV]
            }),
          },
          {
            type: 'spline',
            name: 'AG Delta Vorjahr',
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: arbeitgeberanteil_datatables.map((item) => {
              return [item.year, item.delta_Vorjahr]
            }),
          },
        ],
      },

      headers: [
        {
          text: 'Jahr',
          align: 'start',
          sortable: true,
          value: 'year',
        },
        { text: 'Limit KV', value: 'max_KV' },
        { text: 'Limit Pflege', value: 'max_PV' },
        {
          text: 'Arbeitgeberanteil KV',
          value: 'anteil_AG_KV',
        },
        {
          text: 'Arbeitgeberanteil Pflege',
          value: 'anteil_AG_PV',
        },

        { text: 'AG Delta Vorjahr', value: 'delta_Vorjahr' },
      ],
      arbeitgeberbeitraege: arbeitgeberanteil_datatables,
    }
  },
  created: function() {
    //this.getPercentage()
  },
  methods: {
    getPercentage: function() {
      //console.log(jaeg_datatables)
    },
    getColor(item) {
      if (item > 10) return 'red'
      else if (item > 5) return 'orange'
      else return 'green'
    },
    formatCurrency(item) {
      //console.log(new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item))
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(item)
    },
    toPercent(item, float) {
      const percent = parseFloat(item * 1).toFixed(float) + ' %'
      return percent
    },
  },
}
</script>

<style></style>
