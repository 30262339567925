<template>
  <div>
    <v-card class="mx-auto" :max-width="$vuetify.breakpoint.xs ? 350 : 550">
      <v-card-title style="background-color: #5a5360" class="white--text"
        ><div>{{ leistungen.titel.teaser }}</div>
      </v-card-title>

      <v-card-title style="background-color: #5a5360" class="white--text">
        <h2>{{ leistungen.titel.tarifName }}</h2></v-card-title
      >
      <v-card-text>
        <v-simple-table fixed-header height="100%" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th colspan="2" class="text-left lighten-3">
                  <h2>&nbsp;</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- AMBULANTE LEISTUNGEN -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Ambulante Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td style="width: 50px" :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungen.ambulant.gebuehrenordnung }}</td>
              </tr>
              <!-- ÄRZTLICHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Ärztliche Leistungen:</td>
                <td>{{ leistungen.ambulant.aerztlicheLeistungen }}</td>
              </tr>
              <!-- ARZNEI- UND VERBANDMITTEL -->
              <tr>
                <td :class="tdClass">Arznei- und Verbandmittel:</td>
                <td>{{ leistungen.ambulant.arzneiVerbandmittel }}</td>
              </tr>
              <!-- SEHHILFEN -->
              <tr>
                <td :class="tdClass">Sehhilfen:</td>
                <td>{{ leistungen.ambulant.sehhilfen }}</td>
              </tr>
              <!-- VORSORGE -->
              <tr>
                <td :class="tdClass">Vorsorge:</td>
                <td>{{ leistungen.ambulant.vorsorge }}</td>
              </tr>
              <!-- VORSORGEPAUSCHALE -->
              <tr>
                <td :class="tdClass">Vorsorgepauschale:</td>
                <td>{{ leistungen.ambulant.vorsorgePauschale }}</td>
              </tr>
              <!-- SCHUTZIMPFUNGEN -->
              <tr>
                <td :class="tdClass">Schutzimpfungen:</td>
                <td>{{ leistungen.ambulant.schutzimpfungen }}</td>
              </tr>
              <!-- HEILMITTEL -->
              <tr>
                <td :class="tdClass">Heilmittel:</td>
                <td>{{ leistungen.ambulant.heilmittel }}</td>
              </tr>
              <!-- ALTERNATIVE HEILMETHODEN -->
              <tr>
                <td :class="tdClass">Alternative Heilmethoden:</td>
                <td>{{ leistungen.ambulant.alternativeHeilmethoden }}</td>
              </tr>
              <!-- HEILPRAKTIKER -->
              <tr>
                <td :class="tdClass">Heilpraktiker:</td>
                <td>{{ leistungen.ambulant.heilpraktiker }}</td>
              </tr>
              <!-- HILFSMITTEL -->
              <tr>
                <td :class="tdClass">Hilfsmittel:</td>
                <td>{{ leistungen.ambulant.hilfsmittel }}</td>
              </tr>
              <!-- KÜNSTLICHE BEFRUCHTUNG -->
              <tr>
                <td :class="tdClass">Künstliche Befruchtung:</td>
                <td>{{ leistungen.ambulant.kuenstlicheBefruchtung }}</td>
              </tr>
              <!-- MEDIZINISCHE REHA -->
              <tr>
                <td :class="tdClass">Medizinische Reha:</td>
                <td>{{ leistungen.ambulant.medizinischeReha }}</td>
              </tr>
              <!-- AMBULANTE PSYCHOTHERAPIE -->
              <tr>
                <td :class="tdClass">Ambulante Psychotherapie:</td>
                <td>{{ leistungen.ambulant.ambulantePsychotherapie }}</td>
              </tr>
              <!-- PALLIATIV VERSORGUNG -->
              <tr>
                <td :class="tdClass">Palliativ Versorgung:</td>
                <td>{{ leistungen.ambulant.palliativVersorgung }}</td>
              </tr>
              <!-- SOZIOTHERAPIEN -->
              <tr>
                <td :class="tdClass">Soziotherapien</td>
                <td>{{ leistungen.ambulant.soziotherapien }}</td>
              </tr>
              <!-- TRANSPORT UND FAHRTKOSTEN -->
              <tr>
                <td :class="tdClass">Transport- und Fahrtkosten:</td>
                <td>{{ leistungen.ambulant.transportFahrtkosten }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="2"><h2>&nbsp;</h2></td>
              </tr>
              <!-- STATIONÄRE LEISTUNGEN -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Stationäre Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungen.stationaer.gebuehrenordnung }}</td>
              </tr>
              <!-- ÄRZTLICHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Ärztliche Leistungen:</td>
                <td>{{ leistungen.stationaer.aerztlicheLeistungen }}</td>
              </tr>
              <!-- KRANKENHAUS LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Krankenhaus Leistungen:</td>
                <td>{{ leistungen.stationaer.krankenhausLeistungen }}</td>
              </tr>
              <!-- UNTERKUNFT -->
              <tr>
                <td :class="tdClass">Unterkunft:</td>
                <td>{{ leistungen.stationaer.unterkunft }}</td>
              </tr>
              <!-- PRIVATKLINIKEN -->
              <tr>
                <td :class="tdClass">Privatkliniken:</td>
                <td>{{ leistungen.stationaer.privatkliniken }}</td>
              </tr>
              <!-- ERSATZKRANKENHAUSTAGEGELD -->
              <tr>
                <td :class="tdClass">Ersatzkrankenhaustagegeld:</td>
                <td>{{ leistungen.stationaer.ersatzKrankenhaustagegeld }}</td>
              </tr>
              <!-- ANSCHLUSS REHA -->
              <tr>
                <td :class="tdClass">Anschluss-Reha:</td>
                <td>{{ leistungen.stationaer.anschlussReha }}</td>
              </tr>
              <!-- STATIONÄRE PSYCHOTHERAPIE -->
              <tr>
                <td :class="tdClass">Stationäre Psychotherapie:</td>
                <td>{{ leistungen.stationaer.stationaerePsychotherapie }}</td>
              </tr>
              <!-- HOSPIZ -->
              <tr>
                <td :class="tdClass">Hospiz:</td>
                <td>{{ leistungen.stationaer.hospiz }}</td>
              </tr>
              <!-- TRANSPORTKOSTEN -->
              <tr>
                <td :class="tdClass">Transportkosten:</td>
                <td>{{ leistungen.stationaer.transportkosten }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="2"><h2>&nbsp;</h2></td>
              </tr>
              <!-- ZAHN LEISTUNGEN -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Zahn Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungen.zahn.gebuehrenordnung }}</td>
              </tr>
              <!-- ZAHNBEAHLNDLUNG -->
              <tr>
                <td :class="tdClass">Zahnbehandlung:</td>
                <td>{{ leistungen.zahn.zahnbehandlung }}</td>
              </tr>
              <!-- ZAHNREINIGUNG -->
              <tr>
                <td :class="tdClass">Zahnprophylaxe:</td>
                <td>{{ leistungen.zahn.zahnreinigung }}</td>
              </tr>
              <!-- INLAYS -->
              <tr>
                <td :class="tdClass">Inlays:</td>
                <td>{{ leistungen.zahn.inlays }}</td>
              </tr>
              <!-- ZAHNERSATZ -->
              <tr>
                <td :class="tdClass">Zahnersatz:</td>
                <td>{{ leistungen.zahn.zahnersatz }}</td>
              </tr>
              <!-- IMPLANTATE -->
              <tr>
                <td :class="tdClass">Implantate:</td>
                <td>{{ leistungen.zahn.implantate }}</td>
              </tr>
              <!-- KIEFERORTHOPÄDIE -->
              <tr>
                <td :class="tdClass">Kieferorthopädie:</td>
                <td>{{ leistungen.zahn.kieferorthopaedie }}</td>
              </tr>
              <!-- ZAHNTECHNISCHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Zahntechnische Leistungen:</td>
                <td>{{ leistungen.zahn.zahntechnischeLeistungen }}</td>
              </tr>
              <!-- ZAHNSTAFFEL -->
              <tr>
                <td :class="tdClass">Zahnstaffel:</td>
                <td>{{ leistungen.zahn.zahnstaffel }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="2"><h2>&nbsp;</h2></td>
              </tr>
              <!-- AUSLAND LEISTUNGEN -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Leistungen im Ausland</h2>
                </td>
              </tr>
              <!-- GELTUNGSBEREICH -->
              <tr>
                <td :class="tdClass">Geltungsbereich:</td>
                <td>{{ leistungen.ausland.geltungsbereich }}</td>
              </tr>
              <!-- RÜCKTRANSPORT -->
              <tr>
                <td :class="tdClass">Rücktransport:</td>
                <td>{{ leistungen.ausland.ruecktransport }}</td>
              </tr>
              <!-- ÜBERFÜHRUNG -->
              <tr>
                <td :class="tdClass">Überführung:</td>
                <td>{{ leistungen.ausland.ueberfuehrung }}</td>
              </tr>
              <!-- SERVICES -->
              <tr>
                <td :class="tdClass">Services:</td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungen.ausland.services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
              </tr>
              <!-- BEGRENZUNG -->
              <tr>
                <td :class="tdClass">Begrenzung:</td>
                <td>{{ leistungen.ausland.begrenzung }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="2"><h2>&nbsp;</h2></td>
              </tr>
              <!-- WEITERE LEISTUNGEN -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Weitere Leistungen</h2>
                </td>
              </tr>
              <!-- BEITRAGSRÜCKERSTATTUNG -->
              <tr>
                <td :class="tdClass">Beitragsrückerstattung:</td>
                <td>
                  {{ leistungen.weitereLeistungen.beitragsrueckerstattung }}
                </td>
              </tr>
              <!-- PFLEGEPAUSCHALE -->
              <tr>
                <td :class="tdClass">Pflegepauschale:</td>
                <td>{{ leistungen.weitereLeistungen.pflegePauschale }}</td>
              </tr>
              <!-- VERMITTLUNG VON SPEZILAISTEN -->
              <tr>
                <td :class="tdClass">Vermittlung von Spezialisten:</td>
                <td>
                  {{ leistungen.weitereLeistungen.vermittlungSpezialisten }}
                </td>
              </tr>
              <!--  SERVICE BEHANDLUNGSFEHLER -->
              <tr>
                <td :class="tdClass">Service bei Behandlungsfehlern:</td>
                <td>
                  {{ leistungen.weitereLeistungen.serviceBehandlungsfehler }}
                </td>
              </tr>
              <!--  ENTWÖHNUNGSMAßNAHMEN -->
              <tr>
                <td :class="tdClass">Entwöhnungsmaßnahmen:</td>
                <td>
                  {{ leistungen.weitereLeistungen.entwoehnungsmassnahmen }}
                </td>
              </tr>
              <!-- KURBEHANDLUNG -->
              <tr>
                <td :class="tdClass">Kurbehandlung:</td>
                <td>{{ leistungen.weitereLeistungen.kurbehandlung }}</td>
              </tr>
              <!-- SERVICES -->
              <tr>
                <td :class="tdClass">Services:</td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungen.weitereLeistungen.services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="2"><h2>&nbsp;</h2></td>
              </tr>
              <!-- SONSTIGES -->
              <tr>
                <td colspan="2" class="grey lighten-3">
                  <h2>Sonstiges</h2>
                </td>
              </tr>
              <!--  SELBSTBEHALT -->
              <tr>
                <td :class="tdClass">Selbstbehalt:</td>
                <td>
                  {{ leistungen.sonstiges.selbstbehalt }}
                </td>
              </tr>
              <!--  OPTION -->
              <tr>
                <td :class="tdClass">Option:</td>
                <td>
                  {{ leistungen.sonstiges.option }}
                </td>
              </tr>
              <!--  AUSBILDUNGSVARIANTE -->
              <tr>
                <td :class="tdClass">Ausbildungsvariante:</td>
                <td>
                  {{ leistungen.sonstiges.ausbildungsVariante }}
                </td>
              </tr>
              <!--  STEUERLICHE ABSETZBARKEIT -->
              <tr>
                <td :class="tdClass">Steuerliche Absetzbarkeit:</td>
                <td>
                  {{ leistungen.sonstiges.steuerlicheAbsetzbarkeit }}
                </td>
              </tr>
              <!--  TARIF ART -->
              <tr>
                <td :class="tdClass">Tarifart:</td>
                <td>
                  {{ leistungen.sonstiges.tarifArt }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["leistungen"],
  data() {
    return {
      tdClass: "light-green darken-2 white--text font-weight-bold",
      allianzBlau: "#004A94"
    };
  }
};
</script>