export const fragenVoll = {
  'Frage 1':
    'Ist bei Ihnen eine ambulante oder stationäre Operation, Behandlung, Untersuchung oder Beratung durch einen Arzt, Heilpraktiker oder anderen Therapeuten angeraten, beabsichtigt oder geplant (z.B. Desensibilisierung gegeüber Allergenen, Entfernung der Gallenblase)?',
  'Frage 2':
    'Sind bei Ihnen Kontrolluntersuchungen (keine Gesundheitschecks) oder Nachsorgeuntersuchungen durch einen Arzt, Heilpraktiker oder anderen Therapeuten angeraten oder stehen noch Untersuchungsergebnisse aus? (z. B. Blutzuckerkontrole, Tumornachsorge)',
  'Frage 3':
    'Haben Sie in den letzten 3 Jahren wiederholt oder regelmäßig Medikamente eingenommen oder angewendet (z. B. Tabletten, Tropfen, Spritzen, Salben) oder wurden Medikamente ärztlich angeraten?',
  'Frage 4':
    'Werden oder wurden Sie in den letzten 3 Jahren von einem Arzt, Heilpraktiker oder anderen Therapeuten in den folgenden Bereichen behandelt, beraten oder untersucht? Bestehen oder bestanden bei Ihnen in den letzten 3 Jahren Krankheiten (auch wenn Sie nicht behandelt wurden), Unfallfolgen oder Beschwerden in den folgenden Bereichen?',
  'Frage 4.a)': 'Herz und Kreislauf (z. B. Herzfehler, Herzrhythmusstörung, Angina pectoris, Bluthochdruck)?',
  'Frage 4.b)': 'Blutgefäße (z. B. Hämorrhoiden, Krampfadern, Durchblutungsstörungen)?',
  'Frage 4.c)': 'Atmungsorgane (z. B. Nasenscheidewandverbiegung, Asthma, Bronchitis, Lungenentzündung, Lungenkrebs)?',
  'Frage 4.d)': 'Verdauungsorgane (z. B. Sodbrennen, Galensteine, Fettleber, Magen-/Darmentzündung, Darmkrebs)?',
  'Frage 4.e)': 'Harnorgane (z. B. Zysten, Nierensteine, Nierenbecken-/Blasenentzündung)? ',
  'Frage 4.f)': 'Sterilität oder Fruchtbarkeitsstörung (z. B. eingeschränkte Fruchtbarkeit, Hormonbehandlung, künstliche Befruchtung)?',
  'Frage 4.g)': 'Stoffwechsel (z. B. Diabetes, erhöhte Blutfette, erhöhte Harnsäure, Struma mit/ohne Knoten kalt oder heiß)?',
  'Frage 4.h)': 'Blut (z. B. Blutgerinnungsstörung, Thromboseneigung, Anämie, Leukämie)?',
  'Frage 4.i)': 'Entzündliche Gelenk- oder Bindegewebserkrankungen (z. B. Arthritis, Morbus Bechterew, Rheuma)?',
  'Frage 4.j)': 'Infektionskrankheiten, Geschlechtskrankheiten, Tropenkrankheiten (z. B. Hepatitis, Borreliose, Gürtelrose)?',
  'Frage 4.k)': 'Gehirn oder Nervensystem (z. B. Epilepsie, Gehirnerschütterung, Kopfschmerzen/Migräne, Nervenentzündung)?',
  'Frage 4.l)': 'Geschlechtsorgane (z. B. Myome, Zysten, Brustkrebs, Hodenkrebs)?',
  'Frage 4.m)': 'Rücken (z. B. Bandscheibenvorfal, wiederholte Verspannungen, Wirbelsäulensyndrom, Skoliose)?',
  'Frage 4.n)': 'Kniegelenke, Hüftgelenke, Schultern oder Bewegungsapparat (z. B. Arthrose, Knorpel-/Meniskus-/Bänderschaden, Muskel-/Sehnenriss)?',
  'Frage 4.o)': 'Allergien (z. B. Heuschnupfen, Kontaktalergie, Lebensmittelallergie)?',
  'Frage 4.p)': 'Haut (z. B. Ekzem, Neurodermitis, Schuppenflechte, Hautkrebs)?',
  'Frage 4.q)': 'Augen (z. B. Entzündung, grauer oder grüner Star)?',
  'Frage 4.r)': 'Ohren (z. B. Mittelohrentzündung, Schwerhörigkeit, Tinnitus, Hörsturz)?',
  'Frage 4.s)': 'Sonstige nicht aufgeführte Bereiche (z.B. Anomalien, fehlende Organe/Gliedmaßen, Fremdmaterial nach Operationen, Leistenbruch, Fistel, bösartige/gutartige Neubildungen)?',
  'Frage 5': 'Haben in den letzten 5 Jahren Krankenhaus-/Rehabilitations-/Kuraufenthalte oder ambulante Operationen stattgefunden?',
  'Frage 6':
    'Werden oder wurden Sie in den letzten 5 Jahren wegen psychischer Beschwerden/Erkrankungen (z. B. Psychotherapie, Depression, Trennungsproblematik, Aufmerksamkeitsdefizitsyndrom) oder Suchterkrankungen (z. B. durch Alkohol, Drogen) behandelt, beraten oder untersucht?',
  'Frage 7':
    'Bis 6 Jahre und nur bei Tarifen PZTB03, PZTA03, PZTE03 und/oder OFM02: Besteht oder bestand in den letzten 6 Monaten eine behandlungs-/kontrollbedürftige Entwicklungsverzögerung/Entwicklungsstörung und/oder eine Fehlbildung oder ein Verdacht darauf?',
  'Frage 8': 'Wurde bei Ihnen eine HIV-Infektion festgestelt, ist ein HIV-Test ärztlich angeraten oder steht ein Testergebnis aus?',
  'Frage 9': 'Besteht bei Ihnen eine Erwerbsminderung (MdE), anerkannte Schwerbehinderung (GdB) oder Pflegebedürftigkeit oder wurde diese beantragt? (Bitte Bescheid beifügen)',
  'Frage 10':
    'Sind Sie in zahnärztlicher oder kieferorthopädischer Behandlung (inkl. Kontrolluntersuchungen nach durchgeführten Maßnahmen und Wiedervorstellung) bzw. ist eine solche angeraten oder beabsichtigt (z.B. auch wegen Funktionsstörungen des Kauorgans, Knirscherschienen, Paradontose oder Zahn- bzw. Kieferfehlstellung)?',
  'Frage 11':
    'Ab 14 Jahren: Fehlen Ihnen Zähne, die noch nicht ersetzt sind (nicht Weisheitszähne/Lückenschluss)? Bei „ja“ und einer Anzahl von mehr als 3 fehlenden Zähnen, bitte den Zahngebissbogen einreichen.',
  'Frage 12':
    'Ab 14 Jahren: Haben Sie mehr als 9 ersetzte oder überkronte Zähne (einschließlich Implantaten, Kronen, Brücken einschließlich Ankerkronen, Keramikverblendschalen, herausnehmbaren Teilprothesen, sowie Teleskopkronen mit Prothese)? Bei „ja“ und einer Anzahl von mehr als 15 ersetzten oder überkronten Zähnen, bitte unten Ihren Zahnarzt angeben. Bei „ja“, Anzahl aller ersetzten/überkronten Zähne – ohne durch Vollprothese ersetzte Zähne?',
}
