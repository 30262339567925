export const jaeg_monthly = [
  [1970, 614],
  [1971, 729],
  [1972, 805],
  [1973, 882],
  [1974, 959],
  [1975, 1074],
  [1976, 1189],
  [1977, 1304],
  [1978, 1419],
  [1979, 1534],
  [1980, 1611],
  [1981, 1687.25],
  [1982, 1802.33],
  [1983, 1917.33],
  [1984, 1994],
  [1985, 2070.75],
  [1986, 2147.42],
  [1987, 2185.75],
  [1988, 2300.83],
  [1989, 2339.17],
  [1990, 2415.83],
  [1991, 2492.58],
  [1992, 2607.58],
  [1993, 2761],
  [1994, 2914.33],
  [1995, 2991.08],
  [1996, 3067.75],
  [1997, 3144.42],
  [1998, 3221.17],
  [1999, 3259.5],
  [2000, 3297.83],
  [2001, 3336.17],
  [2002, 3375],
  [2003, 3825],
  [2004, 3862.5],
  [2005, 3900],
  [2006, 3937.5],
  [2007, 3975],
  [2008, 4012.5],
  [2009, 4050],
  [2010, 4162.5],
  [2011, 4125],
  [2012, 4237.5],
  [2013, 4350],
  [2014, 4462.5],
  [2015, 4575],
  [2016, 4687.5],
  [2017, 4800],
  [2018, 4950],
  [2019, 5062.5],
  [2020, 5212.5],
  [2021, 5362.5],
  [2022, 5362.5],
  [2023, 5550],
  [2024, 5775],
  [2025, 6150],
]
