const premiumVBiMen = [
  {
    age: 21,
    premium: 1.62
  },
  {
    age: 22,
    premium: 1.7
  },
  {
    age: 23,
    premium: 1.76
  },
  {
    age: 24,
    premium: 1.84
  },
  {
    age: 25,
    premium: 1.9
  },
  {
    age: 26,
    premium: 1.98
  },
  {
    age: 27,
    premium: 2.06
  },
  {
    age: 28,
    premium: 2.12
  },
  {
    age: 29,
    premium: 2.2
  },
  {
    age: 30,
    premium: 2.28
  },
  {
    age: 31,
    premium: 2.38
  },
  {
    age: 32,
    premium: 2.46
  },
  {
    age: 33,
    premium: 2.56
  },
  {
    age: 34,
    premium: 2.64
  },
  {
    age: 35,
    premium: 2.74
  },
  {
    age: 36,
    premium: 2.84
  },
  {
    age: 37,
    premium: 2.96
  },
  {
    age: 38,
    premium: 3.06
  },
  {
    age: 39,
    premium: 3.18
  },
  {
    age: 40,
    premium: 3.3
  },
  {
    age: 41,
    premium: 3.44
  },
  {
    age: 42,
    premium: 3.58
  },
  {
    age: 43,
    premium: 3.72
  },
  {
    age: 44,
    premium: 3.86
  },
  {
    age: 45,
    premium: 4.02
  },
  {
    age: 46,
    premium: 4.2
  },
  {
    age: 47,
    premium: 4.36
  },
  {
    age: 48,
    premium: 4.56
  },
  {
    age: 49,
    premium: 4.74
  },
  {
    age: 50,
    premium: 4.96
  },
  {
    age: 51,
    premium: 5.18
  },
  {
    age: 52,
    premium: 5.4
  },
  {
    age: 53,
    premium: 5.66
  },
  {
    age: 54,
    premium: 5.92
  },
  {
    age: 55,
    premium: 6.2
  },
  {
    age: 56,
    premium: 6.5
  },
  {
    age: 57,
    premium: 6.82
  },
  {
    age: 58,
    premium: 7.18
  },
  {
    age: 59,
    premium: 7.56
  }
]

exports.premiumVBiMen = premiumVBiMen
