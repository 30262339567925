<template>
  <div>
    <v-card class="mx-auto" :max-width="$vuetify.breakpoint.xs ? 350 : 750">
      <v-card-title style="background-color: #5a5360" class="white--text"
        ><div>Tarifvergleich</div>
      </v-card-title>

      <v-card-title style="background-color: #5a5360" class="white--text">
        <h2>Ärzte Plus vs Ärzte Best</h2></v-card-title
      >
      <v-card-text>
        <v-simple-table fixed-header height="750" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left lighten-3">
                  <h1>Leistungen</h1>
                </th>

                <th class="text-left lighten-3">
                  <h1>MP100</h1>
                </th>

                <th class="text-left lighten-3">
                  <h1>MB100</h1>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- TITEL -->
              <tr>
                <td colspan="3">
                  <h2>&nbsp;</h2>
                </td>
              </tr>
              <!-- AMBULANTE LEISTUNGEN -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Ambulante Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td style="width: 50px" :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungenMP100.ambulant.gebuehrenordnung }}</td>
                <td :class="better">
                  {{ leistungenMB100.ambulant.gebuehrenordnung }}
                </td>
              </tr>
              <!-- ÄRZTLICHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Ärztliche Leistungen:</td>
                <td>{{ leistungenMP100.ambulant.aerztlicheLeistungen }}</td>
                <td>{{ leistungenMB100.ambulant.aerztlicheLeistungen }}</td>
              </tr>
              <!-- ARZNEI- UND VERBANDMITTEL -->
              <tr>
                <td :class="tdClass">Arznei- und Verbandmittel:</td>
                <td>{{ leistungenMP100.ambulant.arzneiVerbandmittel }}</td>
                <td>{{ leistungenMP100.ambulant.arzneiVerbandmittel }}</td>
              </tr>
              <!-- SEHHILFEN -->
              <tr>
                <td :class="tdClass">Sehhilfen:</td>
                <td>{{ leistungenMP100.ambulant.sehhilfen }}</td>
                <td :class="better">
                  {{ leistungenMB100.ambulant.sehhilfen }}
                </td>
              </tr>
              <!-- VORSORGE -->
              <tr>
                <td :class="tdClass">Vorsorge:</td>
                <td>{{ leistungenMP100.ambulant.vorsorge }}</td>
                <td>{{ leistungenMB100.ambulant.vorsorge }}</td>
              </tr>
              <!-- VORSORGEPAUSCHALE -->
              <tr>
                <td :class="tdClass">Vorsorgepauschale:</td>
                <td>{{ leistungenMP100.ambulant.vorsorgePauschale }}</td>
                <td>{{ leistungenMB100.ambulant.vorsorgePauschale }}</td>
              </tr>
              <!-- SCHUTZIMPFUNGEN -->
              <tr>
                <td :class="tdClass">Schutzimpfungen:</td>
                <td>{{ leistungenMP100.ambulant.schutzimpfungen }}</td>
                <td :class="better">
                  {{ leistungenMB100.ambulant.schutzimpfungen }}
                </td>
              </tr>
              <!-- HEILMITTEL -->
              <tr>
                <td :class="tdClass">Heilmittel:</td>
                <td>{{ leistungenMP100.ambulant.heilmittel }}</td>
                <td>{{ leistungenMB100.ambulant.heilmittel }}</td>
              </tr>
              <!-- ALTERNATIVE HEILMETHODEN -->
              <tr>
                <td :class="tdClass">Alternative Heilmethoden:</td>
                <td>{{ leistungenMP100.ambulant.alternativeHeilmethoden }}</td>
                <td>{{ leistungenMB100.ambulant.alternativeHeilmethoden }}</td>
              </tr>
              <!-- HEILPRAKTIKER -->
              <tr>
                <td :class="tdClass">Heilpraktiker:</td>
                <td>{{ leistungenMP100.ambulant.heilpraktiker }}</td>
                <td>{{ leistungenMB100.ambulant.heilpraktiker }}</td>
              </tr>
              <!-- HILFSMITTEL -->
              <tr>
                <td :class="tdClass">Hilfsmittel:</td>
                <td>{{ leistungenMP100.ambulant.hilfsmittel }}</td>
                <td :class="better">
                  {{ leistungenMB100.ambulant.hilfsmittel }}
                </td>
              </tr>
              <!-- KÜNSTLICHE BEFRUCHTUNG -->
              <tr>
                <td :class="tdClass">Künstliche Befruchtung:</td>
                <td>{{ leistungenMP100.ambulant.kuenstlicheBefruchtung }}</td>
                <td>{{ leistungenMB100.ambulant.kuenstlicheBefruchtung }}</td>
              </tr>
              <!-- MEDIZINISCHE REHA -->
              <tr>
                <td :class="tdClass">Medizinische Reha:</td>
                <td>{{ leistungenMP100.ambulant.medizinischeReha }}</td>
                <td>{{ leistungenMB100.ambulant.medizinischeReha }}</td>
              </tr>
              <!-- AMBULANTE PSYCHOTHERAPIE -->
              <tr>
                <td :class="tdClass">Ambulante Psychotherapie:</td>
                <td>{{ leistungenMP100.ambulant.ambulantePsychotherapie }}</td>
                <td :class="better">
                  {{ leistungenMB100.ambulant.ambulantePsychotherapie }}
                </td>
              </tr>
              <!-- PALLIATIV VERSORGUNG -->
              <tr>
                <td :class="tdClass">Palliativ Versorgung:</td>
                <td>{{ leistungenMP100.ambulant.palliativVersorgung }}</td>
                <td>{{ leistungenMB100.ambulant.palliativVersorgung }}</td>
              </tr>
              <!-- SOZIOTHERAPIEN -->
              <tr>
                <td :class="tdClass">Soziotherapien</td>
                <td>{{ leistungenMP100.ambulant.soziotherapien }}</td>
                <td>{{ leistungenMB100.ambulant.soziotherapien }}</td>
              </tr>
              <!-- TRANSPORT UND FAHRTKOSTEN -->
              <tr>
                <td :class="tdClass">Transport- und Fahrtkosten:</td>
                <td>{{ leistungenMP100.ambulant.transportFahrtkosten }}</td>
                <td>{{ leistungenMB100.ambulant.transportFahrtkosten }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="3"><h2>&nbsp;</h2></td>
              </tr>
              <!-- STATIONÄRE LEISTUNGEN -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Stationäre Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungenMP100.stationaer.gebuehrenordnung }}</td>
                <td :class="better">
                  {{ leistungenMB100.stationaer.gebuehrenordnung }}
                </td>
              </tr>
              <!-- ÄRZTLICHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Ärztliche Leistungen:</td>
                <td>{{ leistungenMP100.stationaer.aerztlicheLeistungen }}</td>
                <td>{{ leistungenMB100.stationaer.aerztlicheLeistungen }}</td>
              </tr>
              <!-- KRANKENHAUS LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Krankenhaus Leistungen:</td>
                <td>{{ leistungenMP100.stationaer.krankenhausLeistungen }}</td>
                <td>{{ leistungenMB100.stationaer.krankenhausLeistungen }}</td>
              </tr>
              <!-- UNTERKUNFT -->
              <tr>
                <td :class="tdClass">Unterkunft:</td>
                <td>{{ leistungenMP100.stationaer.unterkunft }}</td>
                <td :class="better">
                  {{ leistungenMB100.stationaer.unterkunft }}
                </td>
              </tr>
              <!-- PRIVATKLINIKEN -->
              <tr>
                <td :class="tdClass">Privatkliniken:</td>
                <td>{{ leistungenMP100.stationaer.privatkliniken }}</td>
                <td>{{ leistungenMB100.stationaer.privatkliniken }}</td>
              </tr>
              <!-- ERSATZKRANKENHAUSTAGEGELD -->
              <tr>
                <td :class="tdClass">Ersatzkrankenhaustagegeld:</td>
                <td>
                  {{ leistungenMP100.stationaer.ersatzKrankenhaustagegeld }}
                </td>
                <td :class="better">
                  {{ leistungenMB100.stationaer.ersatzKrankenhaustagegeld }}
                </td>
              </tr>
              <!-- ANSCHLUSS REHA -->
              <tr>
                <td :class="tdClass">Anschluss-Reha:</td>
                <td>{{ leistungenMP100.stationaer.anschlussReha }}</td>
                <td>{{ leistungenMB100.stationaer.anschlussReha }}</td>
              </tr>
              <!-- STATIONÄRE PSYCHOTHERAPIE -->
              <tr>
                <td :class="tdClass">Stationäre Psychotherapie:</td>
                <td>
                  {{ leistungenMP100.stationaer.stationaerePsychotherapie }}
                </td>
                <td>
                  {{ leistungenMB100.stationaer.stationaerePsychotherapie }}
                </td>
              </tr>
              <!-- HOSPIZ -->
              <tr>
                <td :class="tdClass">Hospiz:</td>
                <td>{{ leistungenMP100.stationaer.hospiz }}</td>
                <td>{{ leistungenMB100.stationaer.hospiz }}</td>
              </tr>
              <!-- TRANSPORTKOSTEN -->
              <tr>
                <td :class="tdClass">Transportkosten:</td>
                <td>{{ leistungenMP100.stationaer.transportkosten }}</td>
                <td :class="better">
                  {{ leistungenMB100.stationaer.transportkosten }}
                </td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="3"><h2>&nbsp;</h2></td>
              </tr>
              <!-- ZAHN LEISTUNGEN -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Zahn Leistungen</h2>
                </td>
              </tr>
              <!-- GEBÜHRENORDNUNG -->
              <tr>
                <td :class="tdClass">Gebührenordnung:</td>
                <td>{{ leistungenMP100.zahn.gebuehrenordnung }}</td>
                <td :class="better">
                  {{ leistungenMB100.zahn.gebuehrenordnung }}
                </td>
              </tr>
              <!-- ZAHNBEAHLNDLUNG -->
              <tr>
                <td :class="tdClass">Zahnbehandlung:</td>
                <td>{{ leistungenMP100.zahn.zahnbehandlung }}</td>
                <td>{{ leistungenMB100.zahn.zahnbehandlung }}</td>
              </tr>
              <!-- ZAHNREINIGUNG -->
              <tr>
                <td :class="tdClass">Zahnprophylaxe:</td>
                <td>{{ leistungenMP100.zahn.zahnreinigung }}</td>
                <td>{{ leistungenMB100.zahn.zahnreinigung }}</td>
              </tr>
              <!-- INLAYS -->
              <tr>
                <td :class="tdClass">Inlays:</td>
                <td>{{ leistungenMP100.zahn.inlays }}</td>
                <td :class="better">{{ leistungenMB100.zahn.inlays }}</td>
              </tr>
              <!-- ZAHNERSATZ -->
              <tr>
                <td :class="tdClass">Zahnersatz:</td>
                <td>{{ leistungenMP100.zahn.zahnersatz }}</td>
                <td :class="better">{{ leistungenMB100.zahn.zahnersatz }}</td>
              </tr>
              <!-- IMPLANTATE -->
              <tr>
                <td :class="tdClass">Implantate:</td>
                <td>{{ leistungenMP100.zahn.implantate }}</td>
                <td :class="better">{{ leistungenMB100.zahn.implantate }}</td>
              </tr>
              <!-- KIEFERORTHOPÄDIE -->
              <tr>
                <td :class="tdClass">Kieferorthopädie:</td>
                <td>{{ leistungenMP100.zahn.kieferorthopaedie }}</td>
                <td :class="better">
                  {{ leistungenMB100.zahn.kieferorthopaedie }}
                </td>
              </tr>
              <!-- ZAHNTECHNISCHE LEISTUNGEN -->
              <tr>
                <td :class="tdClass">Zahntechnische Leistungen:</td>
                <td>{{ leistungenMP100.zahn.zahntechnischeLeistungen }}</td>
                <td :class="better">
                  {{ leistungenMB100.zahn.zahntechnischeLeistungen }}
                </td>
              </tr>
              <!-- ZAHNSTAFFEL -->
              <tr>
                <td :class="tdClass">Zahnstaffel:</td>
                <td>{{ leistungenMP100.zahn.zahnstaffel }}</td>
                <td :class="better">{{ leistungenMB100.zahn.zahnstaffel }}</td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="3"><h2>&nbsp;</h2></td>
              </tr>
              <!-- AUSLAND LEISTUNGEN -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Leistungen im Ausland</h2>
                </td>
              </tr>
              <!-- GELTUNGSBEREICH -->
              <tr>
                <td :class="tdClass">Geltungsbereich:</td>
                <td>{{ leistungenMP100.ausland.geltungsbereich }}</td>
                <td :class="better">
                  {{ leistungenMB100.ausland.geltungsbereich }}
                </td>
              </tr>
              <!-- RÜCKTRANSPORT -->
              <tr>
                <td :class="tdClass">Rücktransport:</td>
                <td>{{ leistungenMP100.ausland.ruecktransport }}</td>
                <td>{{ leistungenMB100.ausland.ruecktransport }}</td>
              </tr>
              <!-- ÜBERFÜHRUNG -->
              <tr>
                <td :class="tdClass">Überführung:</td>
                <td>{{ leistungenMP100.ausland.ueberfuehrung }}</td>
                <td>{{ leistungenMB100.ausland.ueberfuehrung }}</td>
              </tr>
              <!-- SERVICES -->
              <tr>
                <td :class="tdClass">Services:</td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungenMP100.ausland.services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungenMB100.ausland.services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
              </tr>
              <!-- BEGRENZUNG -->
              <tr>
                <td :class="tdClass">Begrenzung:</td>
                <td>{{ leistungenMP100.ausland.begrenzung }}</td>
                <td :class="better">
                  {{ leistungenMB100.ausland.begrenzung }}
                </td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="3"><h2>&nbsp;</h2></td>
              </tr>
              <!-- WEITERE LEISTUNGEN -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Weitere Leistungen</h2>
                </td>
              </tr>
              <!-- BEITRAGSRÜCKERSTATTUNG -->
              <tr>
                <td :class="tdClass">Beitragsrückerstattung:</td>
                <td>
                  {{
                    leistungenMP100.weitereLeistungen.beitragsrueckerstattung
                  }}
                </td>
                <td>
                  {{
                    leistungenMB100.weitereLeistungen.beitragsrueckerstattung
                  }}
                </td>
              </tr>
              <!-- PFLEGEPAUSCHALE -->
              <tr>
                <td :class="tdClass">Pflegepauschale:</td>
                <td>{{ leistungenMP100.weitereLeistungen.pflegePauschale }}</td>
                <td>{{ leistungenMB100.weitereLeistungen.pflegePauschale }}</td>
              </tr>
              <!-- VERMITTLUNG VON SPEZILAISTEN -->
              <tr>
                <td :class="tdClass">Vermittlung von Spezialisten:</td>
                <td>
                  {{
                    leistungenMP100.weitereLeistungen.vermittlungSpezialisten
                  }}
                </td>
                <td>
                  {{
                    leistungenMB100.weitereLeistungen.vermittlungSpezialisten
                  }}
                </td>
              </tr>
              <!--  SERVICE BEHANDLUNGSFEHLER -->
              <tr>
                <td :class="tdClass">Service bei Behandlungsfehlern:</td>
                <td>
                  {{
                    leistungenMP100.weitereLeistungen.serviceBehandlungsfehler
                  }}
                </td>
                <td>
                  {{
                    leistungenMB100.weitereLeistungen.serviceBehandlungsfehler
                  }}
                </td>
              </tr>
              <!--  ENTWÖHNUNGSMAßNAHMEN -->
              <tr>
                <td :class="tdClass">Entwöhnungsmaßnahmen:</td>
                <td>
                  {{ leistungenMP100.weitereLeistungen.entwoehnungsmassnahmen }}
                </td>
                <td>
                  {{ leistungenMB100.weitereLeistungen.entwoehnungsmassnahmen }}
                </td>
              </tr>
              <!-- KURBEHANDLUNG -->
              <tr>
                <td :class="tdClass">Kurbehandlung:</td>
                <td>{{ leistungenMP100.weitereLeistungen.kurbehandlung }}</td>
                <td :class="better">
                  {{ leistungenMB100.weitereLeistungen.kurbehandlung }}
                </td>
              </tr>
              <!-- SERVICES -->
              <tr>
                <td :class="tdClass">Services:</td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungenMP100.weitereLeistungen
                      .services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
                <td>
                  <ul
                    type="square"
                    v-for="service in leistungenMB100.weitereLeistungen
                      .services"
                    :key="service"
                  >
                    <li>{{ service }}</li>
                  </ul>
                </td>
              </tr>

              <!-- BLANK -->
              <tr>
                <td colspan="3"><h2>&nbsp;</h2></td>
              </tr>
              <!-- SONSTIGES -->
              <tr>
                <td colspan="3" class="grey lighten-3">
                  <h2>Sonstiges</h2>
                </td>
              </tr>
              <!--  SELBSTBEHALT -->
              <tr>
                <td :class="tdClass">Selbstbehalt:</td>
                <td>
                  {{ leistungenMP100.sonstiges.selbstbehalt }}
                </td>
                <td>
                  {{ leistungenMB100.sonstiges.selbstbehalt }}
                </td>
              </tr>
              <!--  OPTION -->
              <tr>
                <td :class="tdClass">Option:</td>
                <td>
                  {{ leistungenMP100.sonstiges.option }}
                </td>
                <td>
                  {{ leistungenMB100.sonstiges.option }}
                </td>
              </tr>
              <!--  AUSBILDUNGSVARIANTE -->
              <tr>
                <td :class="tdClass">Ausbildungsvariante:</td>
                <td>
                  {{ leistungenMP100.sonstiges.ausbildungsVariante }}
                </td>
                <td>
                  {{ leistungenMB100.sonstiges.ausbildungsVariante }}
                </td>
              </tr>
              <!--  STEUERLICHE ABSETZBARKEIT -->
              <tr>
                <td :class="tdClass">Steuerliche Absetzbarkeit:</td>
                <td>
                  {{ leistungenMP100.sonstiges.steuerlicheAbsetzbarkeit }}
                </td>
                <td>
                  {{ leistungenMB100.sonstiges.steuerlicheAbsetzbarkeit }}
                </td>
              </tr>
              <!--  TARIF ART -->
              <tr>
                <td :class="tdClass">Tarifart:</td>
                <td>
                  {{ leistungenMP100.sonstiges.tarifArt }}
                </td>
                <td>
                  {{ leistungenMB100.sonstiges.tarifArt }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["leistungenMP100", "leistungenMB100"],
  data() {
    return {
      tdClass: "light-green darken-2 white--text font-weight-bold",
      allianzBlau: "#004A94",
      better: "light-green lighten-4"
    };
  }
};
</script>