<template>
  <!-- App.vue -->
  <div>
    <v-app>
      <!-- ALLIANZ REITH APP BAR-->
      <allianz-reith-app-bar app></allianz-reith-app-bar>

      <!-- ALLIANZ REITH NAVIGATION DRAWER -->
      <allianz-reith-navigation-drawer></allianz-reith-navigation-drawer>

      <!-- Sizes your content based upon application components -->
      <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <!-- If using vue-router -->
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>

      <!-- ALLIANZ REITH FOOTER -->
      <allianz-reith-footer></allianz-reith-footer>
    </v-app>
  </div>
</template>

<script>
import AppBar from "./components/navigations/AppBar";
import NavigationDrawer from "./components/navigations/NavigationDrawer";
import Footer from "./components/navigations/Footer";
export default {
  created() {
    this.$vuetify.theme.light = true;
  },
  data: () => ({
    //
  }),
  components: {
    "allianz-reith-app-bar": AppBar,
    "allianz-reith-navigation-drawer": NavigationDrawer,
    "allianz-reith-footer": Footer
  }
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

