<template>
  <v-container>
    <highcharts :options="chartOptions"></highcharts>
    <v-card>
      <v-card-title>Solidaritätszuschlag 2024</v-card-title>
      <v-card-text>
        Der Solidaritätszuschlag wird erhoben, wenn die Einkommensteuer nach der Grundtabelle (Singles) 18.130 € oder nach der Splittingtabelle (Verheiratete) 36.260 € übersteigt. Der
        Solidaritätszuschlag beträgt <b>5,5 Prozent</b> der Lohn- bzw. Einkommensteuer. Für rund 90% der Steuerzahler entfällt der Soli seit dem 01.01.2021.
      </v-card-text>
    </v-card>
    <p></p>
    <!-- KIRCHENSTEUER -->
    <v-card>
      <v-card-title>Kirchensteuer 2024</v-card-title>
      <v-card-text>
        Die Kirchensteuer wird ggf. auf Grundlage der Einkommensteuer berechnet. In Bayern und Baden Württemberg beträgt sie <b>8 Prozent</b>, in allen anderen Bundesländern <b>9 Prozent</b> der
        Einkommensteuer.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      vorname: '',
      nachname: '',
      chartOptions: {
        title: {
          text: 'Einkommensteuertarif 2024',
        },
        subtitle: {
          text: 'Quelle: BMF Steuerrechner',
        },
        chart: {
          //styledMode: true,
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
          headerFormat: 'Einkommen / Gewinn: {point.x:.2f} EUR<br>',
          pointFormat: '{point.y} ',
        },
        annotations: [
          {
            draggable: '',
            labelOptions: {
              backgroundColor: 'rgba(255,255,255,0.5)',
              verticalAlign: 'top',
              y: 1,
            },
            labels: [
              {
                point: {
                  xAxis: 0,
                  yAxis: 0,
                  x: 5000,
                  y: 39,
                },
                text: '6.1 km climb <br>4.6% on avg.',
              },
            ],
          },
        ],
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Grenzsteuersatz in %',
            },
          },
          //RIGHT
          {
            title: {
              text: 'Durchschnittssteuersatz in %',
            },
            opposite: true,
          },
        ],

        series: [
          {
            type: 'area',
            name: 'Grenzsteuersatz',
            yAxis: 1,
            tooltip: {
              formatter: function() {
                console.log(this)
                return 'x value: ' + this.key + ' y value ' + this.y
              },
              valueDecimals: 1,
              valueSuffix: ' Prozent',
            },
            data: [
              [0, 0],
              [11604, 0],
              [11605, 14],
              [17006, 24],
              [66761, 42],
              [277825, 42],
              [277826, 45],
              [300000, 45],
            ],
          },
          {
            type: 'spline',
            name: 'Durschnittssteuersatz',
            yAxis: 1,
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' Prozent',
            },
            data: [
              [0, 0],
              [10908, 0],
              [10909, 0],
              [16001, 6.37],
              [62810, 26.12],
              [277826, 40.8],
              [300000, 41.4],
            ],
          },
        ],
      },
    }
  },
}
</script>
