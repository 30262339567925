<template>
  <v-container>
    Altersrückstellungen und Verzinsungen
    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Rückstellungen und Verzinsung von 2011 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.allianz.de/" target="_blank">APKV Geschäftsbericht</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Versicherungstechnische Rückstellungen in Mio',
            },
          },
          //LEFT
          {
            title: {
              text: 'Laufende Durschnittsverzinsung',
            },
            min: 0,
            max: 5,
          },
          //RIGHT
          {
            title: {
              text: 'Nettoverzinsung',
            },
            opposite: true,
            min: 0,
            max: 5,
          },
          //RIGHT
          {
            title: {
              text: 'Überschussverwendungsquote',
            },
            opposite: true,
            min: 0,
            max: 100,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Versicherungstechnische Rückstellungen in Mio',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 21432],
              [2012, 22695],
              [2013, 23871],
              [2014, 25002],
              [2015, 26046],
              [2016, 27134],
              [2017, 28360],
              [2018, 29231],
              [2019, 30139],
              [2020, 31262],
              [2021, 32318],
              [2022, 33326],
              [2023, 34355],
            ],
            // sample data
          },
          {
            type: 'spline',
            name: 'Nettoverzinsung',
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 4.3],
              [2012, 4.6],
              [2013, 4.3],
              [2014, 4.1],
              [2015, 4.0],
              [2016, 4.1],
              [2017, 4.1],
              [2018, 3.8],
              [2019, 3.5],
              [2020, 3.4],
              [2021, 3.4],
              [2022, 3.2],
              [2023, 3.5],
            ],
          },
          {
            type: 'spline',
            name: 'Laufende Durschnittsverzinsung',
            yAxis: 2,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 4.3],
              [2012, 3.8],
              [2013, 4.2],
              [2014, 3.9],
              [2015, 3.8],
              [2016, 3.8],
              [2017, 3.39],
              [2018, 3.26],
              [2019, 3.06],
              [2020, 2.85],
              [2021, 2.67],
              [2022, 2.7],
              [2023, 3.5],
            ],
          },
          {
            type: 'spline',
            name: 'Überschussverwedungsquote',
            yAxis: 3,
            marker: {
              enabled: true,
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 83.3],
              [2012, 85.5],
              [2013, 83.0],
              [2014, 81.9],
              [2015, 81.2],
              [2016, 83.9],
              [2017, 84.0],
              [2018, 82.1],
              [2019, 82.8],
              [2020, 83.3],
              [2021, 83.9],
              [2022, 82.0],
              [2023, 82.4],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
