<template>
  <v-container>
    <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>

    <v-spacer></v-spacer>
    <v-slider
      v-model="points[0]"
      color="blue"
      thumb-label="always"
      track-color="grey"
      min="0"
      max="100"
    >
      <template v-slot:prepend>
        <v-icon color="black">mdi-minus</v-icon>
      </template>

      <template v-slot:append>
        <v-icon color="black">mdi-plus</v-icon>
      </template>
    </v-slider>
    <span>{{ points }} {{ firebaseResult }}</span>
    <v-select v-model="chartType" :items="types"></v-select>
  </v-container>
</template>

<script>
import Vue from "vue";
import { Chart } from "highcharts-vue";
import { db } from "../db";

export default {
  components: {
    highcharts: Chart
  },
  firestore: {
    firebaseResult: db.collection("kv_estimates").doc("fCpUBNQOyEQkCxcBDzP1")
  },
  watch: {
    title(newValue) {
      this.chartOptions.title.text = newValue;
    },
    points(newValue) {
      this.chartOptions.series[0].data = newValue;
    },
    firebaseResult(newValue) {
      Vue.set(this.points, 0, newValue.kv_estimates);
    },
    chartType(newValue) {
      this.chartOptions.series[0].type = newValue.toLowerCase();
    },

    animationDuration(newValue) {
      this.updateArgs[2].duration = Number(newValue);
    }
  },
  data() {
    return {
      firebaseResult: [],
      updateArgs: [true, true, { duration: 1000 }],
      mb: "",
      points: [20, 100, 35],
      chartType: "splice",
      types: ["column", "line"],
      chartOptions: {
        title: {
          text: "Actual and estimated numbers Health Insurance"
        },
        subtitle: {
          text: "aim-o-meter"
        },
        series: [
          {
            type: "column",
            name: "KV estimates",
            yAxis: 0,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: " EUR"
            },
            data: []
          }
        ]
      }
    };
  }
};
</script>
