<template>
  <v-stepper v-model="stepper" vertical non-linear>
    <!-- STEP 1 PRSÖNLICHE DATEN-->
    <v-stepper-step
      class
      :color="stepper > 1 ? 'light-green' : 'pink'"
      :complete="stepper > 1"
      step="1"
    >
      Persönliche Daten
      <small
        >Anforderungsformular Gruppentarif Bayerische Landesärztekammer</small
      >
    </v-stepper-step>

    <v-stepper-content step="1" class="pa-xs-0 ma-xs-0 pt-0">
      <v-card color="white" class="pa-xs-0 ma-xs-0 pa-5 ma-5">
        <v-row align="center">
          <!-- ANREDE -->
          <v-col class="d-flex pa-xs-0" cols="12" sm="6">
            <v-select
              color="pink"
              item-color="pink"
              :items="anrede"
              v-model="anredeSelected"
              label="Anrede"
              outlined
            ></v-select>
          </v-col>
          <!-- TITEL -->
          <v-col class="d-flex pa-xs-0 ma-xs-0" cols="12" sm="6">
            <v-select
              :items="titel"
              v-model="titelSelected"
              label="Titel"
              outlined
            ></v-select>
          </v-col>
          <!-- VORNAME -->
          <v-col class="pa-xs-0 ma-xs-0" cols="12" md="6">
            <v-text-field
              outlined
              v-model="vorname"
              :counter="10"
              label="Vorname"
              required
            ></v-text-field>
          </v-col>
          <!-- NACHNAME -->
          <v-col class="pa-xs-0 ma-xs-0" cols="12" md="6">
            <v-text-field
              outlined
              v-model="nachname"
              :counter="10"
              label="Nachname"
              required
            ></v-text-field>
          </v-col>

          <!-- EMAIL -->
          <v-col class="pa-xs-0 ma-xs-0" cols="12" md="6">
            <v-text-field
              outlined
              v-model="email"
              :counter="20"
              label="Email"
              required
            ></v-text-field>
          </v-col>
          <!-- GEBURTSDATUM -->
          <v-col class="pa-xs-0" cols="12" md="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scroll-y-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computedGeburtsdatum"
                  outlined
                  label="Geburtsdatum"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="de"
                transition="scroll-y-transition"
                ref="picker"
                v-model="geburtsdatum"
                :max="
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                    .toISOString()
                    .substr(0, 10)
                "
                :min="
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 55)
                  )
                    .toISOString()
                    .substr(0, 10)
                "
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
      <v-btn color="pink" dark @click="stepper = 2">Weiter</v-btn>
    </v-stepper-content>

    <!-- STEP 2 ANSCHRIFT-->
    <v-stepper-step
      :color="stepper > 2 ? 'light-green' : 'orange'"
      :complete="stepper > 2"
      step="2"
    >
      Ihre Anschrift
      <small>Optional</small>
    </v-stepper-step>

    <v-stepper-content step="2" class="pt-0">
      <v-card color="white" class="pa-5 ma-5">
        <v-row align="center">
          <!-- STRASSE HAUSNUMMER -->
          <v-col cols="12" md="6">
            <v-text-field
              color="orange"
              outlined
              v-model="strasse"
              :counter="30"
              label="Straße / Hausnummer"
              required
            ></v-text-field>
          </v-col>
          <!-- PLZ -->
          <v-col cols="12" md="6">
            <v-text-field
              type="number"
              color="orange"
              outlined
              v-model="plz"
              numbers
              :counter="5"
              label="PLZ"
              required
            ></v-text-field>
          </v-col>
          <!-- ORT -->
          <v-col cols="12" md="6">
            <v-text-field
              color="orange"
              outlined
              v-model="ort"
              :counter="20"
              label="Ort"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-btn outlined @click="stepper = 1" text class="grey--text">Zurück</v-btn
      >&nbsp;
      <v-btn color="orange" dark @click="stepper = 3">Weiter</v-btn>
    </v-stepper-content>

    <!-- STEP 3 EHEPARTNER UND KINDER-->
    <v-stepper-step
      :color="stepper > 3 ? 'light-green' : 'cyan'"
      :complete="stepper > 3"
      step="3"
    >
      Ehepartner und Kinder
      <small>Optional</small>
    </v-stepper-step>
    <v-stepper-content step="3" class="pt-0">
      <v-card color="white" class="pa-5 ma-5">
        <v-row align="center">
          <!-- FACHRICHTUNG -->
          <v-col class="d-flex" cols="12" sm="6">
            <v-switch
              color="cyan"
              v-model="showEhepartner"
              label="Ehepartner"
              value="true"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-show="showEhepartner">
          <!-- VORNAME EHEPARTNER-->
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="vornameEhepartner"
              label="Vorname Ehepartner"
              required
            ></v-text-field>
          </v-col>
          <!-- GEBURTSDATUM EHEPARTNER-->
          <v-col cols="12" md="6">
            <v-menu
              ref="menuEhepartner"
              v-model="menuEhepartner"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="computedGeburtsdatumEhepartner"
                  outlined
                  label="Geburtsdatum Ehepartner"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                year-icon="mdi-calendar-blank"
                ref="picker"
                v-model="geburtsdatumEhepartner"
                :max="
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                    .toISOString()
                    .substr(0, 10)
                "
                :min="
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 75)
                  )
                    .toISOString()
                    .substr(0, 10)
                "
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-switch
              v-model="showKinder"
              color="cyan"
              label="Kinder"
              value="true"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row v-show="showKinder">
          <v-col>
            <v-btn @click="addKid" class="mx-2" fab small dark color="cyan">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>

            <v-btn @click="removeKid" class="mx-2" fab small dark color="cyan">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
            <span>Anzahl: {{ this.kinder.length }}</span>
          </v-col>
        </v-row>

        <v-row
          v-show="showKinder"
          v-for="(kind, index) in kinder"
          v-bind:key="kind.id"
        >
          <!-- VORNAME KIND-->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="kinder[index].vorname"
              outlined
              :label="kind.labelVorname"
              required
            ></v-text-field>
          </v-col>
          <!-- GEBURTSDATUM KIND-->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="kinder[index].geburtsdatum"
              outlined
              :label="kind.labelGeburtsdatum"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-btn outlined @click="stepper = 2" text class="grey--text">Zurück</v-btn
      >&nbsp;
      <v-btn color="cyan" dark @click="stepper = 4">Weiter</v-btn>
    </v-stepper-content>

    <!-- STEP 4 ANGABEN ÄRZTLICHE TÄTIGKEIT-->
    <v-stepper-step
      :color="stepper > 4 ? 'light-green' : 'deep-orange'"
      :complete="stepper > 4"
      step="4"
    >
      Angaben zur ärztlichen Tätigkeit
      <small>Optional</small>
    </v-stepper-step>
    <v-stepper-content step="4" class="pt-0">
      <v-card color="white" class="pa-5 ma-5">
        <v-row align="center">
          <!-- FACHRICHTUNG -->
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              color="deep-orange"
              item-color="deep-orange"
              :items="fachrichtungen"
              v-model="fachrichtungSelected"
              label="Fachrichtung"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-radio-group v-model="berufStatus" row>
              <v-radio
                color="deep-orange"
                label="Student/in"
                value="Student/in"
              ></v-radio>
              <v-radio
                color="deep-orange"
                label="Angestellte/r"
                value="Angestellte/r"
              ></v-radio>
              <v-radio
                color="deep-orange"
                label="niedergelassen"
                value="niedergelassen"
              ></v-radio>
              <v-radio
                color="deep-orange"
                label="Beamte/r"
                value="Beamte/r"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
      <v-btn outlined @click="stepper = 3" text class="grey--text">Zurück</v-btn
      >&nbsp;
      <v-btn color="deep-orange" dark @click="stepper = 5">Weiter</v-btn>
    </v-stepper-content>

    <!-- STEP 5 ZUSAMMENFASSUNG-->
    <v-stepper-step step="5">Zusammenfassung</v-stepper-step>
    <v-stepper-content step="5" class="pt-0">
      <v-card color="grey lighten-4" class="mb-12 pa-5">
        <span>Anrede: {{ anredeSelected }}</span>
        <br />
        <span>Titel: {{ titelSelected }}</span>
        <br />
        <span>Vorname: {{ vorname }}</span>
        <br />
        <span>Nachname: {{ nachname }}</span>
        <br />
        <span>Geburtsdatum: {{ computedGeburtsdatum }}</span>
        <br />
        <span>Fachrichtung: {{ fachrichtungSelected }}</span>
        <br />
        <span>Anzahl Kinder: {{ this.kinder.length }}</span>
        <br />
        <span v-for="kind in kinder" v-bind:key="kind.id">
          Vorname Kind {{ parseInt(kind.id + 1) }}: {{ kind.vorname }}
          <br />
          Geburtsdatum Kind {{ parseInt(kind.id + 1) }}: {{ kind.geburtsdatum }}
          <br />
        </span>
      </v-card>
      <v-btn outlined @click="stepper = 4" text class="grey--text">Zurück</v-btn
      >&nbsp;
      <v-btn color="primary" @click.prevent="submitForm">
        <v-icon left>mdi-send</v-icon>Absenden
      </v-btn>
    </v-stepper-content>
    <!-- ERROR SNACKBAR -->
    <v-snackbar v-model="errorSnackbar" :timeout="5000" color="error" top>
      {{ this.$store.getters.error }}
      <v-btn dark text @click="errorSnackbar = false">Schließen</v-btn>
    </v-snackbar>
  </v-stepper>
</template>

<script>
//import { liste2 } from "./data/facharztliste";

import moment from "moment";
import axios from "axios";

export default {
  data() {
    return {
      paramNachname: "",
      errorSnackbar: false,
      stepper: 1,
      anrede: ["Herr", "Frau"],
      anredeSelected: "",
      titel: ["Kein Titel", "Dr. med.", "Prof. Dr."],
      titelSelected: "",
      vorname: "",
      nachname: "",
      email: "",
      geburtsdatum: null,
      strasse: "",
      plz: "",
      ort: "",
      berufStatus: "",
      fachrichtungen: [
        "Medizinstudent/in",
        "Allgemeinmedizin",
        "Anästhesiologie",
        "Anatomie",
        "Arbeitsmedizin (Betriebsmedizin)",
        "Augenheilkunde",
        "Biochemie",
        "Chirurgie",
        "Frauenheilkunde und Geburtshilfe (Gynäkologie)",
        "Hals-Nasen-Ohrenheilkunde",
        "Haut- und Geschlechtskrankheiten",
        "Humangenetik",
        "Hygiene und Umweltmedizin",
        "Innere Medizin",
        "Kinder- und Jugendmedizin",
        "Kinder- und Jugendpsychiatrie und -psychotherapie",
        "Laboratoriumsmedizin",
        "Mikrobiologie, Virologie und Infektionsepidemiologie",
        "Mund-Kiefer-Gesichtschirurgie",
        "Neurochirurgie",
        "Neurologie",
        "Nuklearmedizin",
        "Öffentliches Gesundheitswesen",
        "Pathologie",
        "Pharmakologie",
        "Phoniatrie und Pädaudiologie",
        "Physikalische und Rehabilitative Medizin",
        "Physiologie",
        "Psychiatrie und Psychotherapie",
        "Psychosomatische Medizin und Psychotherapie",
        "Radiologie",
        "Rechtsmedizin",
        "Strahlentherapie",
        "Transfusionsmedizin",
        "Urologie",
      ],
      fachrichtungSelected: "",
      showEhepartner: false,
      vornameEhepartner: "",
      geburtsdatumEhepartner: "",
      showKinder: false,
      anzahlKinder: 2,
      kinder: [],
      menu: false,
      menuEhepartner: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  created() {
    this.getQRCodeData();
  },
  methods: {
    getQRCodeData() {
      let urlParams = new URLSearchParams(window.location.search);
      this.anredeSelected = urlParams.get("Anrede");
      this.nachname = urlParams.get("Nachname");
      this.vorname = urlParams.get("Vorname");
    },

    submitForm() {
      axios({
        method: "get",
        url:
          "https://www.allianz.reith.de/pkv/mediziner/inc/content/send_it_new.php",
        params: {
          Anrede: this.anredeSelected,
          Titel: this.titelSelected,
          Vorname: this.vorname,
          Nachname: this.nachname,
          Email: this.email,
          Geburtsdatum: this.computedGeburtsdatum,
          Strasse: this.strasse,
          PLZ: this.plz,
          Ort: this.ort,
          Fachrichtung: this.fachrichtungSelected,
        },
      })
        .then((response) => {
          console.log("success", response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    save(geburtsdatum) {
      this.$refs.menu.save(geburtsdatum);
    },
    addKid() {
      console.log(this.kinder.length);
      if (this.kinder.length < 10) {
        this.kinder.push({
          id: this.kinder.length,
          labelVorname: "Vorname Kind " + parseInt(this.kinder.length + 1),
          vorname: "",
          labelGeburtsdatum:
            "Geburtsdatum Kind " + parseInt(this.kinder.length + 1),
          geburtsdatum: "",
        }); // what to push unto the rows array?
      }
    },
    removeKid() {
      //console.log(this.kinder.length);
      //NOT LESS THAN 1 ITEM
      if (this.kinder.length !== 0) {
        this.kinder.pop(); //POP REMOVES LAST ELEMENT OF ARRARY
      }
    },
  },
  computed: {
    computedGeburtsdatum() {
      moment.locale("de");
      return this.geburtsdatum ? moment(this.geburtsdatum).format("LL") : "";
    },
    computedGeburtsdatumEhepartner() {
      moment.locale("de");
      return this.geburtsdatumEhepartner
        ? moment(this.geburtsdatumEhepartner).format("LL")
        : "";
    },
  },
};
</script>
