<template>
  <v-container>
    <span class="text-h6">Gesundheitskosten</span>

    <highcharts :options="chartOptions" style="height: 100%"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    let averageRise = 3.7
    return {
      chartOptions: {
        title: {
          text: 'Gesundheitskosten in Deutschland von 1992 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.gbe-bund.de/" target="_blank">Statistisches Bundesamt</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Gesundheitskosten in Mio',
            },
          },
          //RIGHT
          {
            title: {
              text: 'Prozentualer Anstieg / Senkung',
            },
            opposite: true,
            min: -2,
            max: 9,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Gesundheitskosten in Mio',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [1992, 159381],
              [1993, 163751],
              [1994, 175455],
              [1995, 187525],
              [1996, 195764],
              [1997, 196627],
              [1998, 201655],
              [1999, 207689],
              [2000, 214305],
              [2001, 221559],
              [2002, 229966],
              [2003, 235603],
              [2004, 234813],
              [2005, 241381],
              [2006, 247783],
              [2007, 256173],
              [2008, 266206],
              [2009, 280753],
              [2010, 290462],
              [2011, 295857],
              [2012, 303309],
              [2013, 314639],
              [2014, 327577],
              [2015, 343513],
              [2016, 358651],
              [2017, 375562],
              [2018, 390628],
              [2019, 407446],
              [2020, 440575],
              [2021, 475026],
              [2022, 497661],
              [2023, 494648],
            ],
            // sample data
          },
          {
            type: 'spline',
            name: 'Prozentualer Anstieg / Senkung der Gesundheitskosten p.a.',
            yAxis: 1,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [1993, 2.74],
              [1994, 7.15],
              [1995, 6.88],
              [1996, 4.39],
              [1997, 0.44],
              [1998, 2.56],
              [1999, 2.99],
              [2000, 3.19],
              [2001, 3.38],
              [2002, 3.79],
              [2003, 2.45],
              [2004, -0.34],
              [2005, 2.79],
              [2006, 2.65],
              [2007, 3.39],
              [2008, 3.92],
              [2009, 5.46],
              [2010, 3.49],
              [2011, 1.86],
              [2012, 2.52],
              [2013, 3.74],
              [2014, 4.11],
              [2015, 4.87],
              [2016, 4.41],
              [2017, 4.72],
              [2018, 4.01],
              [2019, 4.31],
              [2020, 8.13],
              [2021, 7.62],
              [2022, 4.77],
              [2023, -0.61],
            ],
          },
          {
            type: 'spline',
            name: 'Ø prozentualer Anstieg der Gesundheitskosten',
            yAxis: 1,
            marker: {
              enabled: false,
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [1993, averageRise],
              [1994, averageRise],
              [1995, averageRise],
              [1996, averageRise],
              [1997, averageRise],
              [1998, averageRise],
              [1999, averageRise],
              [2000, averageRise],
              [2001, averageRise],
              [2002, averageRise],
              [2003, averageRise],
              [2004, averageRise],
              [2005, averageRise],
              [2006, averageRise],
              [2007, averageRise],
              [2008, averageRise],
              [2009, averageRise],
              [2011, averageRise],
              [2012, averageRise],
              [2013, averageRise],
              [2014, averageRise],
              [2015, averageRise],
              [2016, averageRise],
              [2017, averageRise],
              [2018, averageRise],
              [2019, averageRise],
              [2020, averageRise],
              [2021, averageRise],
              [2022, averageRise],
              [2023, averageRise],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
