//STAND 01.01.2023
const beitragV = {
  //BEITRAG MÄNNLICH BISEX
  beitragM21: 1.8,
  beitragM22: 1.86,
  beitragM23: 1.94,
  beitragM24: 2.02,
  beitragM25: 2.08,
  beitragM26: 2.16,
  beitragM27: 2.24,
  beitragM28: 2.32,
  beitragM29: 2.4,
  beitragM30: 2.48,
  beitragM31: 2.56,
  beitragM32: 2.66,
  beitragM33: 2.74,
  beitragM34: 2.84,
  beitragM35: 2.94,
  beitragM36: 3.04,
  beitragM37: 3.14,
  beitragM38: 3.26,
  beitragM39: 3.38,
  beitragM40: 3.5,
  beitragM41: 3.62,
  beitragM42: 3.76,
  beitragM43: 3.9,
  beitragM44: 4.06,
  beitragM45: 4.2,
  beitragM46: 4.38,
  beitragM47: 4.54,
  beitragM48: 4.72,
  beitragM49: 4.92,
  beitragM50: 5.12,
  beitragM51: 5.34,
  beitragM52: 5.58,
  beitragM53: 5.82,
  beitragM54: 6.08,
  beitragM55: 6.36,
  beitragM56: 6.64,
  beitragM57: 6.96,
  beitragM58: 7.3,
  beitragM59: 7.66,

  //BEITRAG WEIBLICH BISEX
  beitragW21: 1.98,
  beitragW22: 2.06,
  beitragW23: 2.14,
  beitragW24: 2.22,
  beitragW25: 2.3,
  beitragW26: 2.38,
  beitragW27: 2.46,
  beitragW28: 2.54,
  beitragW29: 2.62,
  beitragW30: 2.7,
  beitragW31: 2.8,
  beitragW32: 2.88,
  beitragW33: 2.98,
  beitragW34: 3.08,
  beitragW35: 3.18,
  beitragW36: 3.28,
  beitragW37: 3.4,
  beitragW38: 3.52,
  beitragW39: 3.64,
  beitragW40: 3.76,
  beitragW41: 3.88,
  beitragW42: 4.02,
  beitragW43: 4.16,
  beitragW44: 4.32,
  beitragW45: 4.48,
  beitragW46: 4.64,
  beitragW47: 4.82,
  beitragW48: 5.0,
  beitragW49: 5.2,
  beitragW50: 5.4,
  beitragW51: 5.6,
  beitragW52: 5.84,
  beitragW53: 6.08,
  beitragW54: 6.34,
  beitragW55: 6.6,
  beitragW56: 6.88,
  beitragW57: 7.2,
  beitragW58: 7.52,
  beitragW59: 7.88,

  //BEITRAG UNISEX
  beitragU21: 1.96,
  beitragU22: 2.04,
  beitragU23: 2.1,
  beitragU24: 2.18,
  beitragU25: 2.26,
  beitragU26: 2.34,
  beitragU27: 2.42,
  beitragU28: 2.5,
  beitragU29: 2.58,
  beitragU30: 2.66,
  beitragU31: 2.74,
  beitragU32: 2.84,
  beitragU33: 2.92,
  beitragU34: 3.02,
  beitragU35: 3.12,
  beitragU36: 3.24,
  beitragU37: 3.34,
  beitragU38: 3.46,
  beitragU39: 3.58,
  beitragU40: 3.7,
  beitragU41: 3.82,
  beitragU42: 3.96,
  beitragU43: 4.1,
  beitragU44: 4.26,
  beitragU45: 4.42,
  beitragU46: 4.58,
  beitragU47: 4.76,
  beitragU48: 4.94,
  beitragU49: 5.12,
  beitragU50: 5.34,
  beitragU51: 5.54,
  beitragU52: 5.78,
  beitragU53: 6.02,
  beitragU54: 6.28,
  beitragU55: 6.54,
  beitragU56: 6.84,
  beitragU57: 7.14,
  beitragU58: 7.48,
  beitragU59: 7.82,
}

exports.beitragV = beitragV
