<template>
  <tarif :leistungen="leistungen"></tarif>
</template>

<script>
import { aerztePlus } from "./data/tarife/aerzte_plus";
import tarif from "./templates/Tarif.vue";
export default {
  components: {
    tarif
  },
  data() {
    return {
      leistungen: aerztePlus
    };
  }
};
</script>