<template>
  <v-form ref="form">
    <v-card v-show="!thankYou" outlined elevation="1" class="mx-auto my-2 pa-0" :max-width="$vuetify.breakpoint.xs ? 350 : 550">
      <v-img height="250" src="@/assets/blaek.png"></v-img>
      <v-card-title class="font-weight-black overline"
        >Die Partnerschaft zwischen der Bayerischen Landesärztekammer und der Allianz PKV bewährt sich seit mehr als 50 Jahren. Fordern Sie heute ganz einfach Ihr persönliches Angebot an.
      </v-card-title>
      <v-container>
        <!-- ICH WÜNSCHE UNVERBINDLICHES ANGEBOT -->
        <v-row>
          <v-col :class="colClass">
            <v-checkbox :color="allianzBlau" v-model="wunschAngebot" label="Ja, bitte übersenden Sie mir ein unverbindliches Angebot für eine Private Krankenversicherung (Ärztetarife)"></v-checkbox>
          </v-col>
        </v-row>

        <!-- PER EMAIL ODER PER POST -->
        <v-radio-group class="mx-auto ml-2 mb-4" v-model="emailOrPost" row>
          <v-row>
            <v-col class="pb-5"> <v-radio :color="allianzBlau" label="per Email" value="Email"></v-radio></v-col>
            <v-col class="pb-5">
              <v-radio :color="allianzBlau" label="per Post" value="Post"></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>

        <!-- ANREDE -->
        <v-row>
          <v-col :class="colClass">
            <v-select :color="allianzBlau" item-color="blue darken-4" class="font-weight-black" :items="anreden" v-model="anredeSelected" label="Anrede" outlined></v-select>
          </v-col>
        </v-row>

        <!-- TITEL -->
        <v-row>
          <v-col :class="colClass">
            <v-select :color="allianzBlau" item-color="blue darken-4" class="font-weight-black" :items="titel" v-model="titelSelected" label="Titel" outlined></v-select>
          </v-col>
        </v-row>

        <!-- VORNAME -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field :color="allianzBlau" class="font-weight-black" v-model="vorname" label="Vorname" :rules="vornameRules" outlined></v-text-field>
          </v-col>
        </v-row>

        <!-- NACHNAME -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field :color="allianzBlau" class="font-weight-black" v-model="nachname" label="Nachname" :rules="nachnameRules" outlined></v-text-field>
          </v-col>
        </v-row>

        <!-- STRASSE NR -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field :color="allianzBlau" class="font-weight-black" v-model="strasse" label="Strasse / Hausnummer" outlined></v-text-field>
          </v-col>
        </v-row>

        <!-- PLZ -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field :color="allianzBlau" class="font-weight-black" v-model.number="plz" label="PLZ" outlined type="number"></v-text-field>
          </v-col>
        </v-row>

        <!-- ORT -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field :color="allianzBlau" class="font-weight-black" v-model="ort" label="Ort" outlined></v-text-field>
          </v-col>
        </v-row>

        <!-- GEBURTSDATUM -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field type="text" :color="allianzBlau" class="font-weight-black" outlined v-model="geburtsdatum" :rules="geburtsdatumRules" label="Geburtsdatum"></v-text-field>
          </v-col>
        </v-row>

        <!-- EMAIL -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field type="email" :color="allianzBlau" class="font-weight-black" outlined v-model="email" label="Email"></v-text-field>
          </v-col>
        </v-row>

        <!-- FACHRICHTUNG -->
        <v-row style="height: 70px">
          <v-col :class="colClass">
            <v-select :color="allianzBlau" item-color="blue darken-4" class="font-weight-black" :items="fachrichtungen" v-model="fachrichtungSelected" label="Fachrichtung" outlined></v-select>
          </v-col>
        </v-row>

        <!-- PERSÖNLICHE BERATUNG -->
        <v-row>
          <v-col :class="colClass">
            <v-checkbox :color="allianzBlau" v-model="facharzt" label="Ich befinde mich noch in der Ausbildung zum Facharzt/-ärztin."></v-checkbox>
          </v-col>
        </v-row>

        <!-- ICH BIN VERHEIRATET -->
        <v-row style="height: 70px">
          <v-col class="pa-0 ml-4">
            <v-switch class="font-weight-black" v-model="showEhepartner" :color="allianzBlau" label="Ich bin verheiratet" value="true"></v-switch>
          </v-col>
        </v-row>

        <!-- VORNAME EHEPARTNER -->
        <v-row style="height: 70px" v-show="showEhepartner">
          <v-col :class="colClass">
            <v-text-field class="font-weight-black" :color="allianzBlau" v-model="nameEhepartner" outlined label="Name Ehepartner" required></v-text-field>
          </v-col>

          <!-- GEBURTSDATUM EHEPARTNER-->
          <v-col :class="colClass">
            <v-text-field class="font-weight-black" :color="allianzBlau" v-model="geburtsdatumEhepartner" outlined label="Geburtsdatum Ehepartner" required></v-text-field>
          </v-col>
        </v-row>

        <!-- ICH HABE KINDER -->
        <v-row style="height: 70px">
          <v-col class="pa-0 ml-4">
            <v-switch class="font-weight-black" v-model="showKinder" :color="allianzBlau" label="Ich habe Kinder" value="true"></v-switch>
          </v-col>
        </v-row>

        <!-- ANZAHL KINDER -->
        <v-row style="height: 70px" v-show="showKinder" class="mb-5" align="center" justify="center">
          <v-col>
            <v-btn @click="addKid" class="mx-2" fab small dark :color="allianzBlau">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <span class="font-weight-black"> Anzahl: {{ this.kinder.length }}</span></v-col
          >
          <v-col align="right">
            <v-btn @click="removeKid" class="mr-2" fab small dark :color="allianzBlau">
              <v-icon dark>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="" v-show="showKinder" v-for="(kind, index) in kinder" v-bind:key="kind.id">
          <!-- VORNAME KIND-->
          <v-col :class="colClass">
            <v-text-field class="font-weight-black" :color="allianzBlau" v-model="kinder[index].vorname" outlined :label="kind.labelVorname" required></v-text-field>
          </v-col>

          <!-- GEBURTSDATUM KIND-->
          <v-col :class="colClass">
            <v-text-field class="font-weight-black" :color="allianzBlau" v-model="kinder[index].geburtsdatum" outlined :label="kind.labelGeburtsdatum" required></v-text-field>
          </v-col>
        </v-row>

        <!-- PERSÖNLICHE BERATUNG -->
        <v-row>
          <v-col :class="colClass">
            <v-checkbox :color="allianzBlau" v-model="beratungstermin" label="Ich wünsche eine persönliche Beratung zum Thema Private Krankenversicherung, bitte rufen Sie mich an."></v-checkbox>
          </v-col>
        </v-row>

        <!-- TELEFONNUMMER -->
        <v-row>
          <v-col :class="colClass">
            <v-text-field type="number" :color="allianzBlau" class="font-weight-black" outlined v-model="telefonnummer" label="Meine Telefonnummer"></v-text-field>
          </v-col>
        </v-row>

        <!-- ANFRAGE SENDEN -->
        <v-card-actions>
          <v-row>
            <v-col class="pa-0 mt-5 mb-0">
              <v-btn @click.prevent="validateForm" :disabled="!formIsValid" :loading="loading" block outlined x-large :color="allianzBlau">
                Anfrage senden
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-container>
    </v-card>
    <p></p>

    <!-- THANK YOU -->
    <v-card v-show="thankYou" outlined elevation="1" class="mx-auto my-2 pa-0" :max-width="$vuetify.breakpoint.xs ? 350 : 500">
      <v-img height="250" src="@/assets/high_five.png"></v-img>
      <v-card-title class="font-weight-black overline"
        >Vielen Dank für Ihre Anfrage {{ anredeSelected }} {{ this.titelSelected }} {{ this.vorname }} {{ this.nachname }}. Sie werden Ihr persönliches Angebot in Kürze per
        {{ emailOrPost }} erhalten.</v-card-title
      >
    </v-card>
    <!-- SNACKBAR -->
    <v-container>
      <v-snackbar class="p1-10" transition="scroll-y-transition" :color="allianzBlau" centered top rounded v-model="snackbar" timeout="5000">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn width="100" color="white" text v-bind="attrs" @click="snackbar = false">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios'
import { fachrichtungen } from './data/facharztliste'
//import Datenschutz from "./Privacy.vue";
export default {
  components: {
    //privacy: Datenschutz
  },
  created() {
    this.getQRCodeData()
    //console.log(this.$vuetify.breakpoint.name);
  },
  watch: {
    /*
    kinder(newValue) {
      console.log(newValue);
    },
    deep: true
    */
  },
  computed: {
    //AS WE CREATE KINDER ON THE FLY THIS IS WHAT WE NEED BECAUSE VUE DOESNT ACCEPT NON EXISTING UNDEFINED OBJECT IN ARRAYS
    nameKind1() {
      return this.kinder[0] ? this.kinder[0].vorname : ''
    },
    nameKind2() {
      return this.kinder[1] ? this.kinder[1].vorname : ''
    },
    nameKind3() {
      return this.kinder[2] ? this.kinder[2].vorname : ''
    },
    nameKind4() {
      return this.kinder[3] ? this.kinder[3].vorname : ''
    },
    nameKind5() {
      return this.kinder[4] ? this.kinder[4].vorname : ''
    },
    geburtsdatumKind1() {
      return this.kinder[0] ? this.kinder[0].geburtsdatum : ''
    },
    geburtsdatumKind2() {
      return this.kinder[1] ? this.kinder[1].geburtsdatum : ''
    },
    geburtsdatumKind3() {
      return this.kinder[2] ? this.kinder[2].geburtsdatum : ''
    },
    geburtsdatumKind4() {
      return this.kinder[3] ? this.kinder[3].geburtsdatum : ''
    },
    geburtsdatumKind5() {
      return this.kinder[4] ? this.kinder[4].geburtsdatum : ''
    },
  },
  methods: {
    getQRCodeData() {
      let urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('Anrede') == 'W' || urlParams.get('Anrede') == 'weiblich') {
        this.anredeSelected = 'Frau'
      }
      if (urlParams.get('Anrede') == 'M' || urlParams.get('Anrede') == 'männlich') {
        this.anredeSelected = 'Herr'
      }
      this.titelSelected = urlParams.get('Titel')
      this.nachname = urlParams.get('Nachname')
      this.vorname = urlParams.get('Vorname')
      this.strasse = urlParams.get('Strasse')
      this.plz = urlParams.get('PLZ')
      if (urlParams.get('Ort') == 'Munchen') {
        this.ort = urlParams.get('Ort').replace('Munchen', 'München')
      } else {
        this.ort = urlParams.get('Ort')
      }
    },
    addKid() {
      console.log(this.kinder.length)
      if (this.kinder.length < 5) {
        this.kinder.push({
          id: this.kinder.length,
          labelVorname: 'Vorname Kind ' + parseInt(this.kinder.length + 1),
          vorname: '',
          labelGeburtsdatum: 'Geburtsdatum Kind ' + parseInt(this.kinder.length + 1),
          geburtsdatum: '',
        }) // what to push unto the rows array?
      } else {
        this.snackbarText = 'Maximale Anzahl erreicht.'
        this.snackbar = true
      }
    },
    removeKid() {
      //console.log(this.kinder.length);
      //NOT LESS THAN 1 ITEM
      if (this.kinder.length !== 0) {
        this.kinder.pop() //POP REMOVES LAST ELEMENT OF ARRARY
      }
    },
    validateForm() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        console.log('Form is valid')
        this.submitForm()
      } else {
        console.log('Form is not valid')
        this.snackbarText = 'Es fehlen noch Angaben'
        this.snackbar = true
      }
    },
    submitForm() {
      this.loading = true

      axios({
        method: 'get',
        url: 'https://www.allianz.reith.de/pkv/mediziner/inc/content/send_it_blaek.php',
        params: {
          wunschAngebot: this.wunschAngebot,
          per_email_post: this.emailOrPost,
          Anrede: this.anredeSelected,
          Titel: this.titelSelected,
          Vorname: this.vorname,
          Nachname: this.nachname,
          Geburtsdatum: this.geburtsdatum,
          Strasse: this.strasse,
          PLZ: this.plz,
          Ort: this.ort,
          Email: this.email,
          Fachrichtung: this.fachrichtungSelected,
          Facharzt: this.facharzt,
          Name_Ehepartner: this.nameEhepartner,
          Geburtsdatum_Ehepartner: this.geburtsdatumEhepartner,
          Name_Kind_1: this.nameKind1,
          Name_Kind_2: this.nameKind2,
          Name_Kind_3: this.nameKind3,
          Name_Kind_4: this.nameKind4,
          Name_Kind_5: this.nameKind5,
          Geburtsdatum_Kind_1: this.geburtsdatumKind1,
          Geburtsdatum_Kind_2: this.geburtsdatumKind2,
          Geburtsdatum_Kind_3: this.geburtsdatumKind3,
          Geburtsdatum_Kind_4: this.geburtsdatumKind4,
          Geburtsdatum_Kind_5: this.geburtsdatumKind5,
          //Geburtsdatum_Kind_1: this.kinder[0],
          Telefon: this.telefonnummer,
          Beratungstermin: this.beratungstermin,
        },
      })
        .then((response) => {
          this.loading = false
          this.thankYou = true
          console.log('success', response.data)
        })
        .catch((error) => {
          this.loading = false
          this.thankYou = false
          console.log(error.response)
        })
    },
  },
  data() {
    return {
      allianzBlau: '#004A94',
      formIsValid: true,
      validation: [(v) => !!v || 'Name is required'],
      loading: false,
      colClass: 'pa-0 ml-2 mr-2',
      emailOrPost: 'Email',
      anreden: ['Frau', 'Herr', 'X'],
      anredeSelected: '',
      titel: ['Kein Titel', 'Dr. med.', 'Prof. Dr.'],
      titelSelected: '',
      vorname: '',
      vornameRules: [(v) => !!v || 'Bitte geben Sie Ihren Vornamen an'],
      nachname: '',
      nachnameRules: [(v) => !!v || 'Bitte geben Sie Ihren Nachnamen an'],
      strasse: '',
      plz: '',
      ort: '',
      geburtsdatum: '',
      geburtsdatumRules: [(v) => !!v || 'Bitte geben Sie Ihr Geburtsdatum an'],
      telefonnummer: '',
      email: '',
      fachrichtungen: fachrichtungen,
      fachrichtungSelected: '',
      facharzt: false,
      showKinder: false,
      kinder: [],
      showEhepartner: false,
      nameEhepartner: '',
      geburtsdatumEhepartner: '',
      beratungstermin: false,
      wunschAngebot: true,
      thankYou: false,
      snackbar: false,
      snackbarText: '',
    }
  },
}
</script>
<style>
.v-card__text,
.v-card__title {
  text-align: left;
  text-justify: inter-word;
  word-break: normal; /* maybe !important  */
}

.v-snack__wrapper {
  width: 200px;
}
</style>
