<template>
  <div>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon
        @click="toggleDrawer"
        class="grey--text"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="pr-5">
        <span class="grey--text font-weight-light">Allianz.</span>
        <span class="font-italic">Reith.de</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { toggleDrawer } from "../mixins/toggleDrawer";
export default {
  mixins: [toggleDrawer]
};
</script>
