export const hoechstsatzGKV = [
  [1970, 50.31],
  [1971, 59.74],
  [1972, 67.64],
  [1973, 81.14],
  [1974, 91.07],
  [1975, 112.74],
  [1976, 134.33],
  [1977, 148.63],
  [1978, 161.75],
  [1979, 171.79],
  [1980, 183.6],
  [1981, 199.1],
  [1982, 216.28],
  [1983, 226.25],
  [1984, 227.32],
  [1985, 244.35],
  [1986, 261.99],
  [1987, 275.41],
  [1988, 296.8],
  [1989, 301.75],
  [1990, 301.98],
  [1991, 304.09],
  [1992, 331.16],
  [1993, 369.97],
  [1994, 384.7],
  [1995, 424.73],
  [1996, 466.3],
  [1997, 477.96],
  [1998, 492.83],
  [1999, 498.7],
  [2000, 501.27],
  [2001, 507.1],
  [2002, 513.0],
  [2003, 541.65],
  [2004, 558.0],
  [2005, 564.0],
  [2006, 566.44],
  [2007, 566.44],
  [2008, 597.6],
  [2009, 641.29],
  [2010, 631.88],
  [2011, 647.83],
  [2012, 671.29],
  [2013, 691.03],
  [2014, 722.93],
  [2015, 736.31],
  [2016, 764.87],
  [2017, 793.88],
  [2018, 803.14],
  [2019, 841.71],
  [2020, 878.91],
  [2021, 916.71],
  [2022, 916.71],
  [2023, 960.09],
  [2024, 1050.53],
  [2025, 1119.04],
]
