<template>
  <v-container>
    <span class="text-h6">Beitragsentwicklung</span>

    <highcharts :options="chartOptions"></highcharts>
    <p>
      Eintrittsalter: 30 Jahre. GKV 14,6 % + Zusatzbeitrag 1,7 % + SPV Kinderlos 4 %, aus BBG 62.100 EUR = 1.050,53 EUR monatlich. 2 Beitragsentwicklung für das Eintrittsalter 30 nach vollendetem
      Lebensjahr inkl. PPV, KTM07W mit 110 EUR TGS und BTZ. GKV +50% / ø +3,7% p.a., Ärzte Best 100 +22 % / ø +1,8 % p.a., Ärzte Plus 100 +48 % / ø +3,6 % p.a.
    </p>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { beitragsentwicklung_mp100 } from './data/beitragsentwicklung/mp100'
import { beitragsentwicklung_mb100 } from './data/beitragsentwicklung/mb100'
import { beitragsentwicklung_gkv } from './data/beitragsentwicklung/gkv'
//import { jaeg_monthly } from "./data/beitragsbemessungsgrenze/jaeg_monthly";
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Beitragsentwicklung Allianz Ärztetarife vs. GKV von 2013 bis 2024',
        },
        subtitle: {
          text: '',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          shared: true,
        },
        xAxis: {
          crosshair: {
            snap: true,
          },
        },
        yAxis: [
          //LEFT  ARRAY 0
          {
            title: {
              text: 'Beitragsentwicklung Ärzte Plus 100',
            },
            min: 0,
            max: 1200,
          },
          //LEFT 2 ARRAY 1
          {
            title: {
              text: 'Beitragsentwicklung Ärzte Best 100',
            },
            min: 0,
            max: 1200,
          },

          //RIGHT ARRAY 2
          {
            title: {
              text: 'Beitragsentwicklung GKV',
            },
            min: 0,
            max: 1200,
            opposite: true,
          },
        ],

        series: [
          {
            type: 'spline',
            name: 'Beitragsentwicklung Ärzte Plus 100',
            yAxis: 0,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: beitragsentwicklung_mp100,

            // sample data
          },
          {
            type: 'spline',
            name: 'Beitragsentwicklung Ärzte Best 100',
            yAxis: 1,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: beitragsentwicklung_mb100,
          },
          {
            type: 'spline',
            name: 'Höchstsatz GKV',
            yAxis: 2,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: beitragsentwicklung_gkv,
          },
        ],
      },
    }
  },
}
</script>

<style></style>
