export const toggleDrawer = {
  methods: {
    toggleDrawer() {
      if (this.$store.getters.drawer) {
        this.$store.commit('setDrawer', false)
      } else {
        this.$store.commit('setDrawer', true)
      }
    }
  }
}
