<template>
  <v-container>
    <!-- HIGhCHARTS -->
    <highcharts :options="chartOptions" :updateArgs="updateArgs"></highcharts>

    <!-- MEHRBEITRAG FÜR ENTLASTUNG -->
    <span class="text-h5"
      >{{ vPriceComputed | toEUR }} Mehrbeitrag für {{ this.vEntlastung | toEUR }} EUR Entlastung ab 65.<br /><span v-if="vMax">vMax: {{ vMax | toEUR }}</span></span
    >
    <!-- SPACER -->
    <p style="height: 30px"></p>

    <!-- SLIDER -->
    <v-slider
      v-model="vEntlastung"
      step="5"
      label="Entlastung ab 65"
      color="blue"
      thumb-label="always"
      track-color="grey"
      min="0"
      :max="vMax"
      append-icon="mdi-plus"
      prepend-icon="mdi-minus"
      @click:append="vPlus"
      @click:prepend="vMinus"
    >
    </v-slider>

    <v-card outlined elevation="1" class="mx-auto my-2 pa-10" :max-width="$vuetify.breakpoint.xs ? 350 : 500">
      <v-radio-group v-model="geschlecht" row>
        <v-radio label="männlich" value="M"></v-radio>
        <v-radio label="weiblich" value="W"></v-radio>
        <v-radio label="unisex" value="U"></v-radio>
      </v-radio-group>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scroll-y-transition" min-width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field :value="computedGeburtsdatum" outlined label="Geburtsdatum" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker
          locale="de"
          transition="scroll-y-transition"
          ref="picker"
          v-model="geburtsdatum"
          :max="new Date(new Date().setFullYear(new Date().getFullYear() - 21)).toISOString().substr(0, 10)"
          :min="new Date(new Date().setFullYear(new Date().getFullYear() - 59)).toISOString().substr(0, 10)"
          @change="save"
        ></v-date-picker>
      </v-menu>

      <v-select :items="items" v-model="alter" label="Alter" style="width: 200px" outlined></v-select>
      <!-- AKTUELLER KV BEITRAG -->
      <v-text-field v-model.number="aktuellerKVBeitrag" type="number" outlined label="aktueller KV Beitrag" style="width: 250px"></v-text-field>
    </v-card>
  </v-container>
</template>

<script>
//import Vue from 'vue'
import { Chart } from 'highcharts-vue'
import moment from 'moment'
import { premiumVBiMen } from './premiums/premiumVBiMen'
import { beitragV } from './premiums/beitragV_2023'
//import { db } from '../db'

export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      vMax: 0,
      aktuellerKVBeitrag: '450',
      items: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59],
      geschlecht: 'M',
      alter: 30,
      vPrice: 0,
      menu: false,
      geburtsdatum: null,
      premiums: [450, 472.8, 427.8, 327.8],
      premiumNow: 549.5,
      premiumWithV: 572.4,
      premiumWithoutBTZ: 517.44,
      premiumWith65: 190.76,
      //firebaseResult: [],
      updateArgs: [true, true, { duration: 1000 }],
      //mb: '',
      vEntlastung: 100,
      points: ['', '', '', ''],
      chartType: 'splice',
      types: ['column', 'line'],
      chartOptions: {
        chart: {
          type: 'column',
          options3d: {
            enabled: true,
            alpha: 10,
            beta: 25,
            depth: 70,
          },
        },

        title: {
          text: 'Rechner zur Vorsorge Variante (Beitrag Stand 01.01.2023)',
        },
        subtitle: {
          text: 'So können Sie Ihren Beitrag mit 65 senken',
        },
        plotOptions: {
          column: {
            depth: 25,
          },
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:.2f} EUR',
            },
          },
        },
        xAxis: {
          categories: ['Aktueller Beitrag', 'Beitrag bis 60', 'Beitrag von 60 bis 65', 'Beitrag ab 65'],
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Gesamtbeitrag in EUR',
            },
          },
        ],

        series: [
          {
            name: 'Beitragsentlastung mit V',
            colorByPoint: true,
            showInLegend: false,
            yAxis: 0,
            tooltip: {
              followTouchMove: true,
              valueDecimals: 2,
              valueSuffix: ' EUR',
            },
            data: [450, 472.8, 427.8, 327.8],
          },
        ],
      },
    }
  },
  //firestore: {
  //firebaseResult: db.collection('kv_estimates').doc('fCpUBNQOyEQkCxcBDzP1')
  //},
  methods: {
    getVMax() {
      const vMaxTemp = []
      for (let i = 0; i <= 2000; i += 5) {
        let beitragAb65Temp = parseFloat(this.aktuellerKVBeitrag) + parseFloat(i * parseFloat(beitragV['beitrag' + this.geschlecht + this.alter] / 10)) - parseFloat(this.btz) - parseFloat(i)
        let beitragVon60Bis65Temp = beitragAb65Temp + i
        let maximalerRestTemp = (beitragVon60Bis65Temp / 100) * 20
        if (beitragAb65Temp >= maximalerRestTemp) {
          //console.log(i);
          vMaxTemp.push(i)
        }
      }
      console.log(vMaxTemp.slice(-1))
      this.vMax = vMaxTemp.slice(-1)[0]
    },
    save(geburtsdatum) {
      this.$refs.menu.save(geburtsdatum)
    },
    vMinus() {
      this.vEntlastung = this.vEntlastung - 5 || 0
    },
    vPlus() {
      this.vEntlastung = this.vEntlastung + 5 || 800
    },
  },
  computed: {
    btz: function() {
      return (parseFloat(this.aktuellerKVBeitrag) / 100) * 10
    },
    beitragBis60: function() {
      return parseFloat(this.aktuellerKVBeitrag) + parseFloat(this.vPriceComputed)
    },
    beitragVon60Bis65: function() {
      return parseFloat(this.beitragBis60) - parseFloat(this.btz)
    },
    beitragAb65: function() {
      return parseFloat(this.beitragVon60Bis65) - parseFloat(this.vEntlastung)
    },
    vPriceComputed: function() {
      return (parseFloat(beitragV['beitrag' + this.geschlecht + this.alter]) * parseFloat(this.vEntlastung)) / 10
    },
    maximalerRest: function() {
      return (this.beitragVon60Bis65 / 100) * 20
    },
    computedGeburtsdatum() {
      moment.locale('de')
      return this.geburtsdatum ? moment(this.geburtsdatum).format('LL') : ''
    },
    computedAge: {
      get: function() {
        moment.locale('de')
        return this.geburtsdatum ? moment().diff(this.geburtsdatum, 'years') : ''
      },
      set: function(newValue) {
        this.alter = newValue
        console.log('Setter: ' + newValue)
      },
    },
  },
  filters: {
    toEUR(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(value)
    },
  },
  created() {
    this.getVMax()
    console.log('Beitrag ' + this.geschlecht + '30 :' + beitragV['beitrag' + this.geschlecht + this.alter])
    console.log(premiumVBiMen.find((x) => x.age === 28).premium)
    console.log(
      new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(19.6)
    )
  },
  watch: {
    alter(newValue) {
      this.getVMax(newValue)
    },
    geschlecht(newValue) {
      this.getVMax(newValue)
    },
    aktuellerKVBeitrag(newValue) {
      this.getVMax()
      this.$set(this.premiums, 0, parseFloat(newValue))
    },
    beitragBis60(newValue) {
      this.$set(this.premiums, 1, parseFloat(newValue))
    },
    beitragVon60Bis65(newValue) {
      this.$set(this.premiums, 2, parseFloat(newValue))
    },
    beitragAb65(newValue) {
      this.$set(this.premiums, 3, parseFloat(newValue))
    },
    vPriceComputed(newValue) {
      console.log(newValue + this.premiums[0])
      this.$set(this.premiums, 1, newValue + this.premiums[0])
      this.$set(this.premiums, 2, this.premiums[1] - this.btz)
      this.$set(this.premiums, 3, this.premiums[2] - this.vEntlastung)
      //this.premiums[1] = newValue + this.premiums[0];
      console.log(this.premiums[1])
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    title(newValue) {
      this.chartOptions.title.text = newValue
    },
    premiums(newValue) {
      this.chartOptions.series[0].data = newValue

      //this.chartOptions.series[0].data[1] = newValue[1];
      //this.chartOptions.series[0].data[2] = newValue[2];
      //this.chartOptions.series[0].data[3] = newValue[3];
      console.log(newValue)

      //this.$set(this.premiums, 1, newValue[0])
    },

    geburtsdatum(newValue) {
      this.alter = moment().diff(newValue, 'years')
    },
    //firebaseResult(newValue) {
    // Vue.set(this.points, 0, newValue.kv_estimates)
    //},
    //chartType(newValue) {
    //  this.chartOptions.series[0].type = newValue.toLowerCase()
    //},

    //animationDuration(newValue) {
    // this.updateArgs[2].duration = Number(newValue)
    //}
  },
}
</script>
