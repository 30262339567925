<template>
  <v-container>
    <div class="pa-4" v-for="(value, name) in fragenVoll" :key="value">
      <v-card class="mx-auto" :max-width="$vuetify.breakpoint.xs ? 350 : 550"
        ><v-card-title>{{ name }} </v-card-title
        ><v-card-text
          ><span class="body-1">{{ value }}</span></v-card-text
        ><v-card-actions>
          <v-btn-toggle :v-model="Antwort + 'name'">
            <v-btn large block> Ja </v-btn>
            <v-btn block large> Nein </v-btn>
          </v-btn-toggle>
          <!-- <v-chip-group column>
            <v-chip filter outlined> Ja </v-chip>
            <v-chip filter outlined> Nein </v-chip>
          </v-chip-group>--></v-card-actions
        ></v-card
      >
    </div>
  </v-container>
</template>

<script>
import { fragenVoll } from "./data/gesundheitspruefung/fragenVoll2021";
export default {
  data() {
    return {
      fragenVoll,
      antworten: ["Ja", "Nein"],
      "Antwort Frage 1": ""
    };
  }
};
</script>