<template>
  <v-navigation-drawer v-model="drawer" app clipped scroll>
    <v-list nav>
      <!-- ÜBER UNS
      <v-list-item :to="{ path: '/about' }">
        <v-list-item-action>
          <v-icon color="#e67200">mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Über uns</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->

      <!-- GESUNDHEITSKOSTEN -->
      <v-list-item :to="{ path: '/gesundheitskosten' }">
        <v-list-item-action>
          <v-icon color="#78e100">mdi-bottle-tonic-plus-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Gesundheitskosten</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- BEITRAGSBEMESSUNGSGRENZE -->
      <v-list-item :to="{ path: '/beitragsbemessungsgrenze' }">
        <v-list-item-action>
          <v-icon color="#2b34e1">mdi-finance</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Beitragsbemessungsgrenze</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- BEITRAGSENTWICKLUNG -->
      <v-list-item :to="{ path: '/beitragsentwicklung' }">
        <v-list-item-action>
          <v-icon color="blue">mdi-elevation-rise</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Beitragsentwicklung</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- JAEG -->
      <v-list-item :to="{ path: '/jaeg' }">
        <v-list-item-action>
          <v-icon color="purple">mdi-chart-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>JAEG</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- KINDER 
      <v-list-item :to="{ path: '/kinder' }">
        <v-list-item-action>
          <v-icon color="blue">mdi-account-child-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Kinder</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->

      <!-- ARBEITGEBERANTEIL -->
      <v-list-item :to="{ path: '/arbeitgeberanteil' }">
        <v-list-item-action>
          <v-icon style=" " color="green">mdi-factory</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Arbeitgeberanteil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- ZUSATZBEITRAG -->
      <v-list-item :to="{ path: '/zusatzbeitrag' }">
        <v-list-item-action>
          <v-icon style=" " color="red">mdi-plus-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Zusatzbeitrag GKV</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- V-VARIANTE -->
      <v-list-item :to="{ path: '/v-variante' }">
        <v-list-item-action>
          <v-icon style="transform: rotate(135deg)" color="orange">mdi-boomerang</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>V-Variante</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- STEUER -->
      <v-list-item :to="{ path: '/steuer' }">
        <v-list-item-action>
          <v-icon style="transform: rotate(135deg)" color="blue">mdi-kubernetes</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Steuer</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- BLAEK ANFRAGE -->
      <v-list-item :to="{ path: '/blaek' }">
        <v-list-item-action>
          <v-icon color="#00d4bd">mdi-qrcode</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Anfrage</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- TARIFE -->
      <v-list-group :value="false" color="grey" active-class="test123">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-rocket-launch-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tarife</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- ÄRZTE BEST 100 -->
        <v-list-item :to="{ path: '/mb100' }">
          <v-list-item-content>
            <v-list-item-title>Ärzte Best 100</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-stethoscope</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- ÄRZTE PLUS 100 -->
        <v-list-item :to="{ path: '/mp100' }">
          <v-list-item-content>
            <v-list-item-title>Ärzte Plus 100</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-hospital</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- VERGLEICH ÄRZTE PLUS 100 ÄRZTE BEST 100 -->
        <v-list-item :to="{ path: '/mp100vsmb100' }">
          <v-list-item-content>
            <v-list-item-title>Vergleich</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-compare-horizontal</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <!-- ZAHLEN -->
      <v-list-group :value="false" color="grey" active-class="test123">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon color="#5F8078">mdi-counter</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>APKV Zahlen</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- VERZINSUNG -->
        <v-list-item :to="{ path: '/zinsen' }">
          <v-list-item-content>
            <v-list-item-title>Zinsen</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-elevation-rise</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- RfB -->
        <v-list-item :to="{ path: '/rfb' }">
          <v-list-item-content>
            <v-list-item-title>RfB</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-dots-hexagon</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- LEISTUNGEN -->
        <v-list-item :to="{ path: '/leistungen' }">
          <v-list-item-content>
            <v-list-item-title>Ein- und Ausgaben</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-compare-horizontal</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- VERSICHERTE PERSONEN -->
        <v-list-item :to="{ path: '/vps' }">
          <v-list-item-content>
            <v-list-item-title>Versicherte Personen</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-account-supervisor-circle</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!-- Gewinn -->
        <v-list-item :to="{ path: '/gewinn' }">
          <v-list-item-content>
            <v-list-item-title>Gewinn</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon :color="$store.state.allianzBlau">mdi-chart-line</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <!-- DATENSCHUTZ
      <v-list-item :to="{ path: '/datenschutz' }">
        <v-list-item-action>
          <v-icon color="#8000f8">mdi-database-lock</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Datenschutz</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('setDrawer', val)
      },
    },
  },
}
</script>

<style>
.v-list-item__title {
  color: grey;
}
.test123 {
  color: red;
}
.v-list__group__header__prepend-icon .v-icon {
  color: red;
}
</style>
