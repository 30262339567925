export const aerztePlus = {
  titel: {
    tarifName: 'Ärzte Plus 100 (MP100)',
    teaser: 'Ihr starker Komfortschutz',
  },
  ambulant: {
    gebuehrenordnung: 'Bis Höchstsatz 3,5-fach GOÄ',
    aerztlicheLeistungen: '100% für z.B. Untersuchungen und Operationen. Direkter Zugang zu allen Fachärzten',
    arzneiVerbandmittel: '100%, 800 EUR Selbstbehalt pro Jahr auf Arznei- und Verbandmittel.',
    sehhilfen: '100%, max. 250 EUR innerhalb von 24 Monaten',
    vorsorge: '100% für sämtliche medizinisch notwendigen Vorsorgeuntersuchungen ohne Beschränkung auf die gesetzlichen Programme und ohne Altersgrenzen.',
    vorsorgePauschale: 'Pauschalerstattung ausgewählter Vorsorgeuntersuchungen berührt weder Beitragsrückerstattung noch Selbstbehalte',
    schutzimpfungen: '100% für Schutzimpfungen nach Empfehlungs-Liste der Ständigen Impfkommission am Robert-Koch-Institut inkl. Hepatitis-B-Impfung.',
    heilmittel: '100%, bis zu 130% der Kosten nach der Regelung der Bundes-Beihilfe gemäß Verzeichnis, z.B. Physiotherapie, Massagen.',
    alternativeHeilmethoden: '100%, z.B. Akupunktur zur Schmerztherapie, Chirotherapie, Schröpfen, Eigenblutbehandlung.',
    heilpraktiker: 'Keine.',
    hilfsmittel:
      '100 Prozent für bestimmte Hilfsmittel, z.B. Gehhilfen (Höchstgrenzen bei orthopädischen Schuhen, Hörhilfen und Perücken) 80 Prozent für sonstige Hilfsmittel (offener Hilfsmittelkatalog). 100 Prozent, wenn wir die Hilfsmittel für Sie besorgen oder besondere Voraussetzungen erfüllt sind.',
    kuenstlicheBefruchtung: '100% nach vorheriger Zusage, bis zu 4 Versuche je Verfahren zur Kinderwunschbehandlung oder 2 Geburten',
    medizinischeReha: '100% nach vorheriger Zusage mit Höchstbeträgen je nach Indikation',
    ambulantePsychotherapie: '100% für 1. bis 30. Sitzung, danach 70%. Max. 50 Sitzungen pro Versicherungsjahr',
    palliativVersorgung: '100% für eine spezialisierte ambulante Palliativ-Versorgung.',
    soziotherapien: '100%, pro laufender Behandlung bis zu 120 Stunden in 3 Jahren',
    transportFahrtkosten:
      '100 Prozent bei Unfall oder Notfall für die Fahrten eines Notarztes. 100 Prozent bei Dialyse, Strahlen-Therapie bei Krebs oder Chemotherapie, ärztlich bescheinigter Gehunfähigkeit, Unfall oder Notfalltransporte im Rettungswagen oder -hubschrauber. Transporte durch Unternehmen, wenn medizinisches Personal Sie begleiten muss. Fahrten im Taxi, in öffentlichen Verkehrsmitteln oder im privaten Fahrzeug. Bei Fahrten wegen ärztlich bescheinigter Gehunfähigkeit gilt ein Höchstbetrag.',
  },
  stationaer: {
    gebuehrenordnung: 'Über Höchstsatz bis 5,0-fach GOÄ bis Höchstsatz 3,5-fach bei Belegärzten',
    aerztlicheLeistungen: '100% für Chef- und Belegarzt',
    krankenhausLeistungen: '100%, einschl. Rooming-In',
    unterkunft: '100%, auch für das gesondert berechnungsfähige Zweibett-Zimmer.',
    privatkliniken: '100%, nach vorheriger Zusage, Höchstbetrag bei Privatklinik',
    ersatzKrankenhaustagegeld: '40 EUR Mehrbettzimmer, 40 EUR Wahl- oder Belegarzt',
    anschlussReha: '100%, nach vorheriger Zusage',
    hospiz: '100%, nach Vorleistung der Pflegepflichtversicherung',
    stationaerePsychotherapie: '100%',
    transportkosten:
      '100 Prozent bei Unfall oder Notfall für die Fahrten eines Notarztes. 100% für medizinisch notwendige Transporte: im Rettungswagen oder -Hubschrauber. Durch Unternehmen, wenn medizinisches Personal Sie begleiten muss.',
  },
  zahn: {
    gebuehrenordnung: 'Bis Höchstsatz 3,5-fach GOZ',
    zahnbehandlung: '100%',
    zahnreinigung: '100%',
    inlays: '75%',
    zahnersatz: '75%',
    implantate: '75% (max. 6 Implantate je Kiefer)',
    kieferorthopaedie: '75%, bis zum 21. Geburtstag, bei Unfall oder schwerer Erkrankung entfällt die Altersgrenze',
    zahntechnischeLeistungen: '100% bei Zahnbehandlung und Zahnprophylaxe, 75% bei sonstigen Leistungen',
    zahnstaffel:
      'In den ersten 12 / 24 / 36 / 48 Monaten der Versicherungslaufzeit leistet die Allianz maximal bis zu 750 EUR / 1.500 EUR / 2.250 EUR / 3.000 EUR. Ab dem 49. Monat oder nach einem Unfall erhalten Sie die volle Leistung.',
  },
  ausland: {
    geltungsbereich:
      'Ihr Vertrag gilt, solange Sie in der Europäischen Union oder im Europäischen Wirtschaftsraum wohnen. Innerhalb Europas zahlen wir für Leistungen im Land, in dem Sie wohnen, und auf Reisen in ganz Europa. Für Reisen in außereuropäische Staaten zahlen wir bis zu 6 Monaten. Wir verlängern nach gemeinsamer Festlegung um bis zu weitere 5 Jahre.',
    ruecktransport: '100% inkl. Begleitperson',
    ueberfuehrung: '100% für Überführung bei Tod im Ausland',
    services: [
      '24h-Telefonische Erreichbarkeit',
      'Vermittlung von Ärzten und Kliniken im Ausland',
      'Dolmetscher',
      'Betreuung',
      'Information Ihrer Angehörigen',
      'Zahlungsgarantie und Direktabrechnung',
      'Organisation Ihres Kranken-Rücktransort',
      'Organisation der Bestattung im Ausland oder Überführung nach Hause',
    ],
    begrenzung:
      'Wir zahlen die ortsüblichen Kosten zu den Prozentsätzen, die für eine Behandlung in Deutschland gelten. Der Prozentsatz reduziert sich um 20 Punkte, wenn Sie zum Zweck der Behandlung aus der Europäischen Union und dem Europäischen Wirtschaftsraum ausreisen.',
  },
  weitereLeistungen: {
    beitragsrueckerstattung:
      'Bonus Programm bei Leistungsfreiheit. Ab 1. Jahr 15%, 2.Jahr 20%, 3. Jahr 25% und ab 4. Jahr 30% des Jahresbeitrags erfolgsabhängige Beitragsrückerstattung. Bei Kindern 35% bereits ab dem 1. jahr. Nachweislich leistungsfreie Jahre bei privaten Vorversicherern werden angerechnet.',
    pflegePauschale: 'Einmalige Pflegepauschale von 3.000 EUR, wenn die versicherte Person pflegebedürftig ist und mindestens der Pflegegrad 3 vorliegt.',
    vermittlungSpezialisten: 'Bei Diagnose gemäß Diagnose- und Therapieverzeichnis Vermittlung eines Termins bei einem von uns anerkannten Spezialisten, einmalig pro Versicherungsfall.',
    serviceBehandlungsfehler: 'Kostenlose Information und Hilfe bei Verdachtsfällen.',
    kurbehandlung: 'Keine',
    entwoehnungsmassnahmen:
      '100% für die erste und 70% für die zweite und dritte von maximal drei Entwöhnungsmaßnahmen (ambulant und stationär) - außer für Nikotinsucht- nach vorheriger Zusage vor Behandlungsbeginn, bei stationärem Entzug mit Höchstbetrag.',
    haeuslicheKrankenpflege: '100%',
    organTransplantation: '100% bei Lebend-Organspende einschließlich Ersattung der beim Organspender anfallenden Kosten.',
    services: [
      'Bonus Check',
      'VIVY',
      'Gesundheits App (Rechungen einreichen per App)',
      'Arzt- und Kliniksuche',
      'Allianz gegen Schmerz',
      'Doc on Call',
      'BetterDoc (Zweitmeinung)',
      'Patientenbegleiter',
    ],
  },
  sonstiges: {
    selbstbehalt: '800 EUR pro Jahr auf Arznei- und Verbandmittel.',
    option: 'Option auf Höherversicherung zum 6. oder 8. Versicherungsjahr bis Alter 52 Jahre.',
    ausbildungsVariante: 'Günstige Ausbildungsvariante ohne Altersrückstellungen während Facharztausbildung und Studium möglich. Höchstalter 39 Jahre.',
    steuerlicheAbsetzbarkeit: '84,07 %',
    tarifArt: 'Geschlechtsunabhängig (Unisex)',
  },
}
