<template>
  <v-container>
    GKV vs PKV
    <p></p>

    <v-row>
      <v-col class="px-4">
        <v-range-slider
          v-model="range"
          hint="Bitte geben Sie ihr Alter und die Laufzeit an"
          :max="max"
          :min="min"
          thumb-label="always"
          hide-details
          persistent-hint
          class="align-center"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="range[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              v-model="range[0]"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="range[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              v-model="range[1]"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    Aktueller Beitrag: {{ beitragStart }}
    <p>Laufzeit: {{ runtime }} Jahre</p>
    <!-- BEITRAGSSTEIGERUNG PRO JAHR IN % ZINS -->
    <v-text-field
      v-model.number="zins"
      type="number"
      outlined
      label="Beitragssteigerung pro Jahr in %"
      step=".1"
      style="width: 250px;"
    ></v-text-field>
    <p>Zins: {{ zins }} %</p>

    <p></p>

    <p></p>
    <p>
      Beitrag über Gesamtlaufzeit: {{ totalAmount | toEUR }} EUR
      <highcharts :options="chartOptions"></highcharts>
    </p>

    <v-data-table
      :sort-desc="true"
      :headers="headers"
      :items="dataTables"
      :items-per-page="100"
      class="elevation-1"
    >
    </v-data-table
  ></v-container>
</template>

<script>
import { Chart } from "highcharts-vue";
export default {
  components: {
    highcharts: Chart,
  },
  created() {
    this.zinsesZinsMethod();
  },
  filters: {
    toEUR(value) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(value);
    },
  },
  watch: {
    zins(newValue) {
      this.zinsesZinsMethod();
      console.log(newValue);
    },
    runtime(newValue) {
      this.chartOptions.title.text =
        "GKV von " +
        this.range[0] +
        " bis " +
        this.range[1] +
        " (" +
        (this.range[1] - this.range[0]) +
        " Jahre Laufzeit)";
      this.zinsesZinsMethod();
      console.log(newValue);
    },
  },
  methods: {
    zinsesZinsMethod() {
      this.beitragData = [[this.currentYear, this.beitragStart]];
      this.dataTables = [];
      let accu = this.beitragStart;
      let lastAccu = this.beitragStart;

      for (let i = 1; i <= this.runtime; i++) {
        let lastElement = this.dataTables.slice(-1).pop();
        console.log(lastElement);
        //let dataArray = [];
        //lastAccu = (accu / (100 + this.zins)) * 100;
        accu = accu + (parseFloat(accu) * parseFloat(this.zins)) / 100;
        //console.log(dataArray, i);
        //WRITE

        this.beitragData.push([i + this.currentYear, accu]);
        this.dataTables.push({
          year: i + this.currentYear,
          beitrag: accu,
          test: accu / 100,
          test2: lastAccu,
        });
      }
      //CHANGE DATA IN HIGHCHART
      this.chartOptions.series[0].data = this.beitragData;
    },
  },
  computed: {
    runtime: function() {
      return this.range[1] - this.range[0];
    },
    totalAmount: function() {
      let sum = 0;
      this.beitragData.forEach((e) => {
        //console.log(e[1]);
        sum += parseFloat(e[1]) * 12;
      });
      return sum - this.beitragStart * 12;
    },
  },
  data() {
    return {
      min: 18,
      max: 120,
      range: [30, 85],
      currentYear: 2021,
      beitragStart: 920,
      //runtime: 30,
      zins: 5.87,

      beitragData: [],
      dataTables: [],
      headers: [
        {
          text: "Jahr",
          align: "start",
          sortable: true,
          value: "year",
        },
        { text: "JAEG (jährlich)", value: "beitrag" },
        { text: "JAEG (monatlich)", value: "test" },
        {
          text: "Diff. zu Vorjahr (jährlich absolut)",
          value: "test2",
        },
        {
          text: "Diff. zu Vorjahr (monatlich absolut)",
          value: "spread_monthly_absolute",
        },
        { text: "Diff. zu Vorjahr (prozentual)", value: "spread_procentual" },
      ],
      chartOptions: {
        title: {
          text: "",
        },
        subtitle: {
          text: "Quelle: Allianz Reith",
        },
        chart: {
          zoomType: "xy",
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: "KV Beitrag in EUR",
            },
          },
          /*RIGHT
          {
            title: {
              text: "",
            },
            opposite: true,
            min: -2,
            max: 9,
          },*/
        ],

        series: [
          {
            type: "column",
            name: "Beitrag in EUR",
            yAxis: 0,
            tooltip: {
              valueDecimals: 2,
              valueSuffix: " EUR",
            },
            data: null, //ONLY AT INIT GETS INITIAL DATA FROM CREATED

            // sample data
          },
        ],
      },
    };
  },
};
</script>

<style></style>
