<template>
  <v-container>
    Gewinn, Eigenkapital und Verwaltungskostenquote
    <highcharts :options="chartOptions"></highcharts>
  </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Gewinn, Eigenkapital und Verwaltungskosten von 2011 bis 2023',
        },
        subtitle: {
          text: 'Quelle: <a href="http://www.allianz.de/" target="_blank">APKV Geschäftsberichte</a>',
        },
        chart: {
          zoomType: 'xy',
        },
        tooltip: {
          followTouchMove: true,
          shared: true,
        },
        xAxis: {
          crosshair: true,
        },
        yAxis: [
          //LEFT
          {
            title: {
              text: 'Jahresüberschuss in Mio',
            },
            min: 0,
            max: 400,
          },
          //LEFT
          {
            title: {
              text: 'Verwaltungskostenquote in %',
            },
            min: 0,
            max: 5,
          },
          //RIGHT
          {
            title: {
              text: 'Eigenkapital in Mio',
            },
            min: 0,
            max: 500,
            opposite: true,
          },
          //RIGHT
          {
            title: {
              text: 'Eigenkapitalquote in %',
            },
            opposite: true,
            min: 0,
            max: 15,
          },
        ],

        series: [
          {
            type: 'column',
            name: 'Jahresüberschuss in Mio',
            yAxis: 0,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 100],
              [2012, 110],
              [2013, 119],
              [2014, 118],
              [2015, 104],
              [2016, 94],
              [2017, 100],
              [2018, 104],
              [2019, 106],
              [2020, 106],
              [2021, 130],
              [2022, 138],
              [2023, 135],
            ],
            // sample data
          },

          {
            type: 'column',
            name: 'Eigenkapital in Mio',
            yAxis: 2,
            tooltip: {
              valueDecimals: 0,
              valueSuffix: ' EUR',
            },
            data: [
              [2011, 333],
              [2012, 345],
              [2013, 345],
              [2014, 363],
              [2015, 338],
              [2016, 338],
              [2017, 338],
              [2018, 338],
              [2019, 338],
              [2020, 335],
              [2021, 238],
              [2022, 489],
              [2023, 321],
            ],
          },
          {
            type: 'spline',
            name: 'Verwaltungskostenquote',
            yAxis: 1,
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' %',
            },
            data: [
              [2011, 2.7],
              [2012, 2.5],
              [2013, 2.4],
              [2014, 2.4],
              [2015, 2.4],
              [2016, 2.5],
              [2017, 2.6],
              [2018, 2.6],
              [2019, 2.5],
              [2020, 2.4],
              [2021, 2.4],
              [2022, 2.3],
              [2023, 2.3],
            ],
          },
          {
            type: 'spline',
            name: 'Eigenkapitalquote',
            yAxis: 3,
            marker: {
              enabled: true,
            },
            tooltip: {
              valueDecimals: 2,
              valueSuffix: ' %',
            },
            data: [
              [2011, 10.2],
              [2012, 10.5],
              [2013, 10.5],
              [2014, 11.2],
              [2015, 11.9],
              [2016, 11.8],
              [2017, 11.6],
              [2018, 11.2],
              [2019, 9.5],
              [2020, 6.4],
              [2021, 6.1],
              [2022, 12.2],
              [2023, 7.7],
            ],
          },
        ],
      },
    }
  },
}
</script>

<style></style>
