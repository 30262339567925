export const beitragsbemessungsgrenze = [
  [1970, 613.55],
  [1971, 728.59],
  [1972, 805.28],
  [1973, 881.98],
  [1974, 958.67],
  [1975, 1073.71],
  [1976, 1188.75],
  [1977, 1303.79],
  [1978, 1418.83],
  [1979, 1533.88],
  [1980, 1610.57],
  [1981, 1687.26],
  [1982, 1802.3],
  [1983, 1917.34],
  [1984, 1994.04],
  [1985, 2070.73],
  [1986, 2147.43],
  [1987, 2185.77],
  [1988, 2300.81],
  [1989, 2339.16],
  [1990, 2415.85],
  [1991, 2492.55],
  [1992, 2607.59],
  [1993, 2760.98],
  [1994, 2914.36],
  [1995, 2991.06],
  [1996, 3067.75],
  [1997, 3144.45],
  [1998, 3221.14],
  [1999, 3259.49],
  [2000, 3297.83],
  [2001, 3336.18],
  [2002, 3375.0],
  [2003, 3450.0],
  [2004, 3487.5],
  [2005, 3525.0],
  [2006, 3562.5],
  [2007, 3562.5],
  [2008, 3600.0],
  [2009, 3675.0],
  [2010, 3675.0],
  [2011, 3712.5],
  [2012, 3825.0],
  [2013, 3937.5],
  [2014, 4050.0],
  [2015, 4125.0],
  [2016, 4237.5],
  [2017, 4350.0],
  [2018, 4425.0],
  [2019, 4537.5],
  [2020, 4687.5],
  [2021, 4837.5],
  [2022, 4837.5],
  [2023, 4987.5],
  [2024, 5175.0],
  [2025, 5512.5],
]
