<template>
  <v-footer height="40" padless app>
    <privacy></privacy>
    <v-card
      class="mx-auto"
      color="#d0d0d0"
      dark
      style="width: 100%"
      flat
      ripple
      tile
    >
      <v-card-text class="py-2 white--text text-center caption">
        <span style="color: #004a94">
          <strong
            >{{ new Date().getFullYear() }} – Allianz Reith –
            <a
              style="color: #004a94"
              target="_blank"
              href="https://vertretung.allianz.de/michael.reith/impressum/"
              >Impressum</a
            >
            –
            <span
              class="text-decoration-underline"
              style="cursor: pointer; color: #004a94"
              @click="showDatenschutz"
              >Datenschutz</span
            ></strong
          ></span
        >
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import Datenschutz from "../Privacy.vue";
export default {
  components: {
    privacy: Datenschutz
  },
  methods: {
    showDatenschutz() {
      this.$store.commit("setDatenschutz", true);
    }
  }
};
</script>
